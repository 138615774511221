// Import style sheets
@import url(fonts/Akira_stylesheet.css);
@import url('https://fonts.google.com/specimen/Nunito+Sans&display=swap');
//@import url('https://fonts.google.com/specimen/Montserrat');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');



//Sample to import specific font weights
// @import url('https://fonts.google.com/specimen/Montserrat:semi-bold,600&display=swap');
//<link href='//fonts.googleapis.com/css?family=Montserrat:thin,extra-light,light,100,200,300,400,500,600,700,800'
//rel='stylesheet' type='text/css'>

// Variables

//New Colour Scheme
$kkLemonLime: #e4ff1a;
$kkLemonLimeDark: #96A811;
$kkBlack: #000000;
$kkDarkGrey: #123a3a;
$kkLimeGreen: #1affa8;
$kkPink: #ff1a72;
$kkBlue: #251aff;
$kkUltraViolet: #604B8D;
$kkUltraVioletVeryLight: #EFD5FF;
$kkUltraVioletLight: #745BAB;
$kkUltraVioletDark: #5A4785;
$kkWhite: #FCFCFC;
$kkRoseRed: #BE3455;
$kkEerieBlack: #1A1D1A;
$kkPear: #C2E812;





//$RoyalBlue: #3b8cc4;
$RoyalBlue: #008dc9;
$RoyalBlueDarker: #2662a8;

$LightRed: #FF8A8A;
$CherryRed: #ff5757;
$CherryRedDarker: #d14545;

$greySuperLight: #dbdbdb; //For border lines only
$greyLighter: #e9e9e9; //Darker border and background
$grey: #9e9e9e; //Standard
$greyBackground: #bfbfbf;
$greyShadow: #f0f0f0;

$black: #1a1a1a;
$white: #ffffff;









$largeTablet: 1100px;
$tablet: 900px;
$midTablet: 790px;
$phone: 600px;
$smallPhone: 400px;

// Font Specification
// NEW  FONT DEFINITION







html, body, section, div, Title, h1, h2, h3, h4, h5, h6, a, nav, span, button, input, textarea  {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}



// html, body, section, div, h1, h2, h3, h4, h5, h6, a, nav, span, button, input, textarea {
//   font-family: 'Akira Expanded', sans-serif;
// }

/****************************************************************************************************************
OLD FONT DEFINITION
html, body, section, div, h1, h2, h3, h4, h5, h6, a, nav, span, button, input, textarea {
  font-family: 'Poppins', Montserrat, sans-serif;
}
****************************************************************************************************************/


h1 {
  font-weight: 600;
  font-size: 19.5px;
}

h2 {
  font-weight: 800;
  font-size: 40px;
  color: #000000;
}

h3, h4 {
  font-weight: 600;
}

// Used in Header
h5 {



  /*
  //THIS IS WHAT WAS FROM THE FIGMA CSS CODE OUTPUT
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  */

  // SLIGHTLY MODIFIED FROM TOM'S CODE
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.00em;

}

h6 {
  font-weight: 500;
}

span  {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-heigh: 16.5px;
  font-Height: 35px;
}

p {
  font-weight: 400;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
}

a {
  &:visited {
    color: $black;
  }
}

.bold {
  font-weight: 700;
}

.blue {
  color: $RoyalBlue;
}

.blue-dark {
  color: $RoyalBlueDarker;
}

.green {
  color: #009938;
}

.grey {
  color: $grey;
}

// add in the kk colours classes and class names
.kkUltraViolet {
  color: $kkUltraViolet;
}


.error {
  color: $CherryRed !important;
}

.error-block {
  background-image: linear-gradient(45deg, $CherryRedDarker 15%, $CherryRed 65%);
  color: $white;
  text-align: center;
  padding: 10px;

  p {
    margin: 0;
  }
}

.barrier {
  width: 2px;
  height: 53px;
  background-color: $grey;
}

.barcode {
  font-family: 'Libre Barcode 39 Text', cursive;
  font-size: 40px;

  @media (max-width: 350px) {
    font-size: 30px;
  }
}

span.link-span {
  cursor: pointer;
  color: $black;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

h2.blue-black-title {

  font-family: 'Akira Expanded', sans-serif;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-block-end: 0.2em;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  span {

    font-family: 'Akira Expanded', sans-serif;

    color: $kkUltraViolet;   // was $RoyalBlue
                              // update the "LET's get you moving" and "WHAT do you want to store" where the
                              // capital letters are the appropriate colour as mentioned here
  }
  @media (max-width: $phone) {
    font-size: 30px;
  }
}

h2.blue-black-title-Montserrat {

  // font-family: 'Akira Expanded', sans-serif;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-block-end: 0.2em;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  span {

    // font-family: 'Akira Expanded', sans-serif;
    // font-family: 'Montserrat', sans-serif;
    color: $kkUltraViolet;   // was $RoyalBlue
    // update the "LET's get you moving" and "WHAT do you want to store" where the
    // capital letters are the appropriate colour as mentioned here
  }
  @media (max-width: $phone) {
    font-size: 30px;
  }
}


.loading-spinner {
  width: 3em;
  height: 3em;
  margin: 30px auto;
  display: inline-block;
  border: 3px solid rgba(0,0,0,0.3);
  border-radius: 50%;
  border-top-color: #000000;
  animation: 1s spinnySpin infinite ease-in-out;
}

@keyframes spinnySpin {
  to {
    transform: rotate(360deg);
  }
}

button.smallButton {
  border: none;
  color: $kkLemonLime;   // was $RoyalBlue - not sure what this was for
  background: transparent;
  text-transform: none;
  padding: 0;
  outline: none;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    color: $black;
    text-decoration: underline;
  }
}

button.submitButton {   // Buttons such as "Next" and "Suggested package" and "Contact Us"
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 125px;
  padding: 0 20px;
  height: 45px;
  font-size: 18px;
  color: $kkEerieBlack;    // was $kkEerieBlack
  // text-transform: uppercase;
  background-color: $kkLemonLime;   /// was $RoyalBlue - small buttons like "Next" and "Contact Us" and "Suggested packages"
  border: none;
  border-radius: 10px;
  transition: all 400ms linear;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &:disabled {
    background-color: $greyBackground;
    &:hover {
      background-image: none;
      background-color: $greyBackground;
    }
  }

  &:hover {
    color: $kkWhite;
    background-color: $kkUltraViolet;
    // mike didn't like the lemonlime / limegreen gradient background-image: linear-gradient(45deg, $kkLemonLime 15%, $kkLimeGreen 65%);
    // was background-image: linear-gradient(45deg, $RoyalBlueDarker 15%, $RoyalBlue 65%);
  }

  img {
    width: 18px;
    margin-left: 10px;
  }

  div.loading-spinner {
    width: 1em;
    height: 1em;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top-color: $white;
  }

  &:focus {
    outline: none;
  }
}

button.red {
  background: $CherryRed !important;

  &:hover {
    background-image: linear-gradient(45deg, $CherryRedDarker 15%, $CherryRed 65%) !important;
  }

  i {
    margin-right: 5px;
  }
}

nav ul li {
  list-style: none;
  padding: 0;
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active  {
//     -webkit-box-shadow: 0 0 0 30px white inset !important;
//     box-shadow: 0 0 0 30px white inset !important;
// }

textarea:focus, input:focus{
  outline: none;
}

.line {
  width: 95%;
  margin: 50px;
  height: 1px;
  background: $greySuperLight;
}

//App Content
.App {
  text-align: center;
}

.App-header {
  background-color: $kkEerieBlack;
  display: flex;
  align-items: center;
  justify-content: center;
  //font-size: calc(10px + 2vmin);
  //font-size: 18;
  //font-heigh: 25px;
  color: $kkWhite;
  width: 100%;
  Height: 115px;
  position: fixed;
  z-index: 1069;

  nav.navbar {   // header bar on the webpage (header.tsx)
    padding: 0px;   //  was 20px, but changed to 0 to remove the white border around the header
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    // box-shadow: 0 10px 10px -2px #e6e6e6;

    // border-bottom: 100px solid $kkUltraViolet;  // add an Ultraviolet bottom bar to the header


    .containerConvincer {

      //left: 0px;
      width: 100%;  // changed to 95% so the logo in the top left isn't at the very edge of the page the contact us button on the right isn't at the very edge of the page
      height: 115px;
      //align-items: left;

      max-width: 11000px;   // was 1100px (made max width way bigger) otherwise it would not fill all the way
      // padding: 5px 0;

      color: $kkWhite;

      background-color: $kkUltraViolet;



      span {
        //font-family: 'Akira Expanded', sans-Serif;
        color: $kkWhite;
        text-align: center;
        //font-size: 8;
        //font-weight: 400;
        bottom: -12px;
        //position:absolute;
        // display: flex;
        // justify-content: center;
        // align-items: center;


        white-space:nowrap;
        //left: +5vw;

        //width: calc(100% - 200px);
        width: auto;
        overflow: visible;
        white-space: nowrap;
        text-overflow: ellipsis;

        //left: +1%;
        //position:relative;
      }

      span.ConvincerBar1 {
        text-align: center;
        font-size: 16px;
        font-height: 35px;
        color: $kkWhite;
        @media(max-width: $smallPhone) {
          text-align: center;
        }
      }

      span.ConvincerBar2 {
        text-align: center;
        font-size: 16px;
        font-height: 35px;
        color: $kkWhite;
        @media(max-width: $smallPhone+140) {
          display: none;
        }
      }

      span.ConvincerBar3 {
        text-align: center;
        font-size: 16px;
        font-height: 35px;
        color: $kkWhite;
        @media(max-width: $phone+80) {
          display: none;
        }
      }

      span.ConvincerBar4 {
        text-align: center;
        font-size: 16px;
        font-height: 35px;
        color: $kkWhite;
        @media(max-width: $tablet+15) {
          display: none;
        }
      }

      span.ConvincerBar5 {
        text-align: center;
        font-size: 16px;
        font-height: 35px;
        color: $kkWhite;
        @media(max-width: $largeTablet+200) {
          display: none;
        }
      }



      ul.CCnavbar-nav {
        display: flex;
        margin: 0;
        padding: 0px;


        li {
          &.menu-itemCC1 {

            font-size: 16px;
            font-height: 35px;
            color: $kkWhite;  // was Black;
            margin: 0 20px;

            @media(max-width: $smallPhone+140) {
              text-align: center;
            }

          }
          &.menu-itemCC2 {
            // font-size: 1rem;
            font-size: 16px;
            font-height: 35px;
            color: $kkWhite;  // was Black;
            margin: 0 20px;
            //margin-top: 10px;

            @media(max-width: $smallPhone+140) {
              display: none;
            }
          }
          &.menu-itemCC3 {
            // font-size: 1rem;
            font-size: 16px;
            font-heigh: 35px;
            color: $kkWhite;  // was Black;
            margin: 0 20px;
            @media(max-width: $midTablet+10) {
              display: none;
            }

          }
          &.menu-itemCC4 {
            // font-size: 1rem;
            font-size: 16px;
            font-height: 35px;
            color: $kkWhite;  // was Black;
            margin: 0 20px;
            @media(max-width: $largeTablet) {
              display: none;
              }
          }

          &.menu-itemCC5 {
            // font-size: 1rem;
            font-size: 16px;
            font-height: 35px;
            color: $kkWhite;  // was Black;
            margin: 0 20px;
            @media(max-width: $largeTablet+375) {
              display: none;
            }
          }


        }
      }

    }  //containerConvincer

///////////////////////////////////////////////////////////////////////

    .container {

      overflow: hidden;
      position: fixed;
      top: 0;

      display: flex;
      width: 100%;  // changed to 95% so the logo in the top left isn't at the very edge of the page the contact us button on the right isn't at the very edge of the page
      height: 75px;
      justify-content: space-between;
      align-items: center;
      max-width: 11000px;   // was 1100px (made max width way bigger) otherwise it would not fill all the way
      background-color: $kkEerieBlack;  // was blank aka added new line of code to change the background colour


      section.nav-exclude-img {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;

      }


      a {
        &.navbar-brand {


          padding: 65px;
          display: flex;
          justify-content: center;
          align-items: center;

          @media(max-width: $phone) {
            padding: 15px;
          }

        }

        // Took this out because it was forcing either the KitKeeper logo in the top left to be too narrow or the login icon to be too wide
        // img {
        //   width: 25px;  // changed from 7em;
        // }
      }

      ul.navbar-nav {
        display: flex;
        margin: 0;
        padding: 20px;


        li {

          &.menu-item {
            // font-size: 1rem;
            font-size: 16px;
            font-heigh: 35px;
            color: $kkWhite;  // was Black;
            margin: 0 20px;


            a {
              text-decoration: none;
              color: $kkWhite;  // was $black;

              &:hover {
                color: $kkLemonLime;  // was $RoyalBlue
              }

              &:visited {
                color: $kkWhite;  // was $black
              }

              &.active {
                color: $kkWhite;  // was $RoyalBlue
              }
            }
          }


          /* &.social-icons {
            color: $kkWhite;   // was $RoyalBlue;
            font-size: 1.1rem;
            margin: 0 8px;

            a {
              color: transparent;
              -webkit-text-stroke-color: rgb(255, 255, 255);
              // was -webkit-text-stroke-color: rgb(0, 141, 201);
              // This is where the colour of the social media icons is set, uses RBG instead of the HexTriplet code
              // instagram, facebook, twitter, linkedin
              -webkit-text-stroke-width: 1px;
            }
          } */

        }
      }

      div.pipe-div {
        color: $black;
        vertical-align: baseline;
        font-size: 1rem;
        margin: 0 20px;
      }

      ul.icons {
        margin-right: 20px;
      }

      ul.toggle {
        display: none;
        margin: 0 20px;
        padding: 0;
        justify-content: center;
        align-items: center;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          i {
            font-size: 1.2rem;
            color: $black;
          }
        }
      }

      div.contact-button {
        min-width: 190px;
        padding-right: 30px;

        a, button {
          text-decoration: none;
        }

        span {
          font-family: 'Montserrat', sans-serif;
          font-weight: 600;
          color: $kkEerieBlack;
          // background-color: $kkEerieBlack;
          // display: block;
        }

        i {
          color: white;
          margin-right: 10px;
        }
      }
    }

    }




    @media(max-width: $largeTablet) {
    nav.navbar {
      .container {
        ul.items, div.pipe-div {
          display: none;
        }

        ul.toggle {
          display: flex;
        }
      }
    }
  }

  @media(max-width: $midTablet) {
    nav.navbar {
      .container {
        div.contact-button {
          min-width: 0;
          button {
            min-width: 0;
            i {
              margin-right: 0;
            }
            span {
              // display: none;   // This hid the text in the Contact Us button in the header
            }
          }
        }
      }
    }
  }

  @media(max-width: $phone) {
    nav.navbar {
      .container {
        ul.icons {
          display: none;
        }
      }
    }
  }
}

.spinning {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.App-white-box {
  margin: 0 auto;
  padding-top: 10em;
  padding-bottom: 7em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
}

.App-numerical-indicator {
  width: 100%;
  max-width: 1000px;
  //margin-left: -70px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $largeTablet) {
      width: 750px;
  }
  @media (max-width: 800px) {
      width: 86vw;
      //margin-left: -40px;
  }
  @media (max-width: $midTablet) and (orientation: landscape) {
    //margin-left: -50px;
  }
  @media (max-width: $phone) {
    width: 75vw;
    //margin-left: -15px;
  }

  .indicator-line {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;

    span {   // Navigation bar 1 - 2 - 3 - BOX - 4 - 5 - 6
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background-image: none;
      background-color: $grey;
      background-size: 200% auto;
      animation: effect 1s linear infinite;
      border-radius: 25px;
      color: $white;
      font-size: 20px;
      font-weight: 700;

      @media (max-width: $midTablet) {
        width: 5vw;
        height: 5vw;
        font-size: 12px;
        font-weight: 600;
      }
      @media (max-width: $phone) {
        width: 8vw;
        height: 8vw;
        font-weight: 500;
      }
      @media (max-width: 350px) {
        font-weight: 500;
      }
    }

    &.active {

      &::before {
        background-image: linear-gradient(to right, $kkUltraVioletLight 20%, $kkUltraViolet 40%, $kkUltraVioletDark 60%, $kkUltraViolet 80%);
        // was background-image: linear-gradient(to right, $kkUltraViolet 50%, $kkUltraVioletLight 100%, $kkUltraVioletLight 150%,  $kkUltraViolet 200%);
       //  was background-image: linear-gradient(to right, $RoyalBlue 20%, $RoyalBlueDarker 40%, $RoyalBlueDarker 60%, $RoyalBlue 80%);
      }

      &:nth-child(3) {
        &::after {
          background-image: linear-gradient(to right, $kkUltraVioletLight 20%, $kkUltraViolet 40%, $kkUltraVioletDark 60%, $kkUltraViolet 80%);
          // was background-image: linear-gradient(to right, $kkUltraVioletLight 20%, $kkUltraViolet 40%, $kkUltraVioletDark 60%, $kkUltraViolet 80%);
          // was background-image: linear-gradient(to right, $RoyalBlue 20%, $RoyalBlueDarker 40%, $RoyalBlueDarker 60%, $RoyalBlue 80%);
        }
      }


      span {
        background-image: linear-gradient(to right, $kkUltraVioletLight 20%, $kkUltraViolet 40%, $kkUltraVioletDark 60%, $kkUltraViolet 80%);
        // was background-image: linear-gradient(to right, $RoyalBlue 20%, $RoyalBlueDarker 40%, $RoyalBlueDarker 60%, $RoyalBlue 80%);
      }
    }

    span.box {   // Centre graphic in the navigation bar (Normally a box)
      width: 100px;
      height: 100px;
      border: 3px solid $white;
      border-radius: 53px;
      box-shadow: 1px 1px 10px 2px $greyShadow;
      background-image: none;
      background-color: $kkUltraViolet;  // was $RoyalBlue

      @media (max-width: $midTablet) {
          width: 9vw;
          height: 9vw;
          border: 2px solid $white;
      }

      img {
          width: 60%;
      }
    }

    &::before {
      background-image: none;
      background-color: $grey;
      background-size: 200% auto;
      position: relative;
      width: 100px;
      height: 7px;
      content: counter(step);
      counter-increment: step;
      color: rgba(0,0,0,0);
      animation: effect 1s linear infinite;

      @media (max-width: $largeTablet) {
          width: 50px;
      }
      @media (max-width: $midTablet) {
          width: 9vw;
          height: 3px;
      }
      @media (max-width: $phone) {
        width: 5vw;
        height: 4px;
      }
    }

    &:first-child, &:nth-child(4) {
      &::before {
        width: 0;
        display: none;
      }
    }

    &:nth-child(3) {
      &::after {
        background-image: none;
        background-color: $grey;
        background-size: 200% auto;
        position: relative;
        width: 100px;
        height: 7px;
        content: counter(step);
        counter-increment: step;
        color: rgba(0,0,0,0);
        animation: effect 1s linear infinite;

        @media (max-width: $largeTablet) {
            width: 50px;
        }
        @media (max-width: $midTablet) {
            width: 9vw;
            height: 3px;
        }
        @media (max-width: $phone) {
          width: 0;
        }
      }
    }
  }

  .box-indicator {
    width: auto;
      @media (max-width: $phone) {
        display: none;
      }
  }
}

div.payment-figure {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;

  h4 {
    font-size: 20px;
    margin: 8px 0;
  }

  span.cost {
    color: $kkUltraViolet;   // was $RoyalBlue;  Upfront payment £0.00 and £0.00 Monthly payment summary at bottom of ship / store page
    font-weight: 700;
    font-size: 35px;
    padding: 0 10px;

    &:first-child {
      border-right: 1px solid $grey;
    }
  }
}

// Landing.tsx
div.landing-all-box-container {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: $largeTablet) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $phone) {
    grid-template-columns: repeat(1, 1fr);
  }

  // Tile icons for ship or store book.kitkeeper.co.uk
  section.those-freaking-boxes, section.rollover {
    background-image: none;
    background: $kkWhite;   // if we want to use the KK colourscheme here like KKpink, KKyello or KKlimegreen, etc...
    max-width: 300px;
    max-height: 300px ;
    border: 1px solid $kkUltraVioletVeryLight;   // was $greyLighter; lightening the ultraviolet colour
    box-shadow: 1px 1px 10px 2px $greyShadow;
    border-radius: 10px;   // was 1px; wanted to make the tiles more rounded at the corners
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 30px;           // was 20px; want to pull tiles higher for smaller height rendering
    padding-bottom: 70px;   // was 20px; want to pull tiles higher for smaller height rendering
    flex-basis: 50%;
    height: 80%;
    cursor: pointer;

    div.content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4px 2px;   // was 40px 20px; want to pull tiles higher for smaller height rendering

      img {
        width: auto; // was auto      change the image sizes so they aren't shrunken down too much
        height: 60px;  // was 60px
      }

      h3 {
        font-size: 40px;  // was 20px
        color: $kkBlack;   // was #1a1a1a;
      }

      div.jessieJ-priceTag {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        border: 1px solid $kkUltraVioletVeryLight;  // was $grey
        border-radius: 25px/50%;
        width: 85%;
        margin: 20px 0;
        color: $kkUltraViolet;   // was $RoyalBlue
        font-size: 30px;
        font-weight: 600;
        min-height: 1px;     // was 40px:  Shrink the "Store Boxes" and "Ship Boxes" tiles
        max-height: 1px;     // New Line:  Shrink the "Store Boxes" and "Ship Boxes" tiles

        @media(max-width: $midTablet) {
          font-size: 20px;
        }

        @media(max-width: $largeTablet) and (orientation: landscape) {
          font-size: 20px;
        }
      }

      p {
        font-size: 14px;
        margin: 15px;
      }

      ul {
        padding-inline-start: 0;
        display: flex;
        flex-direction: column;
        width: 100%;

        li {
          list-style: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
          border-bottom: 1px solid $greySuperLight;

          span {
            margin-right: 40px;
            font-weight: 600;
            text-align: left;
          }

          img {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    button {
      position: absolute;
      bottom: 0;
      margin: 0 auto;
      width: 100%;
      text-transform: none;
      font-weight: 600;
      font-size: 16px;
      min-height: 60px;
      transition: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  section.those-freaking-boxes {
    div.content {
      h3, span {
        color: $kkBlack;   // was# 1a1a1a
      }

      p {
        color: $grey;   // made this so the smaller text on the landing tiles / buttons is gret
      }

      div.jessieJ-priceTag {
        border: none;
      }

      img {
        &.static-state {
          display: block;
        }
        &.rollover-state {
          display: none;
        }
      }
    }

    button {
      background-image: linear-gradient(45deg, $kkUltraViolet 15%, $kkUltraVioletDark 65%);
      // was  background-image: linear-gradient(45deg, $RoyalBlueDarker 15%, $RoyalBlue 65%);
      color: $white;

      &:hover {
        background: $white;
        color: $kkUltraViolet;
      }
    }
  }
  section.rollover {
    background-image: linear-gradient(45deg, $kkUltraViolet 15%, $kkUltraVioletDark 65%);
    // was  background-image: linear-gradient(45deg, $RoyalBlueDarker 15%, $RoyalBlue 65%);

    margin-top: 0;
    transition: all 0.5s ease-in-out;

    div.content {
      h3, p, span, div.jessieJ-priceTag {
        color: $white;
      }

      div.jessieJ-priceTag {
        border: none;
      }

      ul {
        li {
          border-bottom: 1px solid $RoyalBlueDarker;
          img {
            width: 15px;
            height: 15px;
          }
        }
      }

      img {
        &.static-state {
          display: none;
        }
        &.rollover-state {
          display: block;
        }
      }
    }

    button {
      background: $kkWhite;
      color: $kkUltraVioletDark;    // was $RoyalBlue
    }
  }
}


form {

  button {
    margin: 20px auto;

    div {
      display: flex;
      align-items: center;
    }
  }

  label.title-label {
    width: 100%;
    margin: 0;
    height: auto;
    color: $kkUltraViolet;
    font-weight: 700;
    border-bottom: 1px solid $greySuperLight;
    text-align: left;
  }

  label.address-label,
  label.payment-label,
  label.log-and-reg-label {
    border-bottom: none;
    color: $grey;
    font-size: 12px;
  }

  label.payment-label {
    margin: 0 5px;
    text-transform: uppercase;
  }

  @media (max-width: $phone) {
    margin-top: 10px;
  }

  label.display-Login-Name-label {
    font-size: 200;
    text-align: right;
    color:  $CherryRed;
  }


  //Boxes.tsx
  section.boxes-and-cost-section {
    display: flex;
    margin: 20px 50px;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: 1200px) {
      margin: 20px;
    }
    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  div.all-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (max-width: $phone) {
      justify-content: space-between;
    }
  }

  div.item-div {
    width: 200px;
    position: relative;
    border: 2px solid $greyLighter;
    box-shadow: 1px 1px 10px 2px $greyShadow;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    margin: 20px;
    overflow: hidden;
    //min-height: 280px;

    div.item-div-front {
      padding-top: 32px;
      //height: 280px;
      transition: 0.3s all linear;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      //align-items: center;
      align-self: stretch;
    }

    div.item-div-back {
      width: 200px;
      //height: 280px;
      position: relative;
      background-color: #00385f;
      padding-top: 32px;
      color: $white;
      transition: 0.3s all linear;
      align-self: stretch;

      div {
        margin: 10px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        text-align: left;

        &.item-description {
          // item description
          height: 135px;
          overflow-y: auto;
          font-size: small;
        }
      }

      h1 {
        font-family: 'Akira Expanded', sans-Serif;
        color: $kkUltraViolet;
        font-size: 24px;
        font-weight: 800;
      }

      h6 {
        margin: 0;
        color: #5ba7db;
        font-size: 24px;
        font-weight: 800;
      }
    }

    @media (max-width: $phone) {
      height: auto;
      div.item-div-front {
        width: 100%;
        height: auto;
        padding-top: 0;
      }
      div.item-div-back {
        height: auto;
        padding-top: 0;
      }
      div.img-and-name {

        span {
          text-align: left;
        }
      }
    }

    div.img-and-name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img.item {
        //margin: 30px 30px 10px 30px;
        max-width: 60%;
        max-height: 110px;
      }

      img.itemBubble {
        width: 50%;
      }

      @media (max-width: $phone) {
        img.item {
          //margin: 30px 30px 10px 30px;
          width: 15%
        }
      }
    }

    img.info {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 20px;
      height: auto;
      cursor: pointer;
    }

    span {
      font-weight: 600;
      margin: 10px;
    }

    div.input {
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-top: 2px solid $greyLighter;
      margin-top: 30px;
      overflow: hidden;

      input {
        flex-basis: 60%;
        font-weight: 600;
        width: 50%;
        border-width: 0;
        font-size: 16px;
        padding: 5px 10px;
        text-align: center;
      }

      div {
        flex-basis: 20%;
        padding: 0 5px;
        background-color: #d9d9d9;
        color: $white;
        font-weight: 800;
        font-size: 25px;
        transition: all 0.4s linear;
        user-select: none;

        &:hover {
          cursor: pointer;
          background-color: $kkUltraViolet;
        }

        &:last-child {
          background-color: $greyBackground;

          &:hover {
            cursor: pointer;
            background-color: $kkUltraViolet;
          }
        }
      }
    }

    @media (max-width: $phone) {
      width: 100%;
      margin: 0 10px;
      padding: 10px 0;
      justify-content: space-between;
      border-radius: 0;
      border: none;
      border-bottom: 2px solid $greyLighter;
      box-shadow: none;

      div.item-div-front {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .img-and-name {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          img.info {
            display: none;
          }

          img.item {
            // width: 30px;
            // height: 30px;
            margin: 0 5px;
          }
        }
      }

      div.input {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border: 2px solid $greyLighter;
        border-radius: 10px;
        margin: 0;
        max-width: 120px;
        width: 100%;
        height: 36px;
        overflow: hidden;

        input {
          flex-basis: 60%;
          font-weight: 600;
          width: 100%;
          border-width: 0;
          font-size: 16px;
          padding: 5px 10px;
          text-align: center;

          &:focus {
            outline: none;
          }
        }

        div {
          flex-basis: 20%;
          padding: 0 5px;
          background-color: #d9d9d9;
          color: $white;
          font-weight: 800;
          font-size: 25px;
          transition: all 0.4s linear;

          &:hover {
            cursor: pointer;
            background-color: $kkUltraViolet;
          }

          &:last-child {
            background-color: $greyBackground;

            &:hover {
              cursor: pointer;
              background-color: $kkUltraViolet;
            }
          }

        }
      }
    }
  }

  section.total-shipping-cost-box {
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    width: 100%;
    max-width: 300px;
    box-shadow: 1px 1px 10px 2px $greyShadow;
    margin: 20px 0;
    border-right: 3px solid $kkUltraViolet;
    padding: 10px 0;

    section.all-content {
      display: flex;
      flex-direction: column;
      margin: 5px 20px;

      div.payment-figure {
        justify-content: flex-end;
        text-align: right;
      }
    }

    div.title {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      text-align: right;
      h2.blue-black-title {
        justify-content: flex-end;
        text-align: right;
        font-weight: 500;
        margin: 0;
        font-size: 30px;
        span {
          color: $kkUltraViolet;
        }
      }
    }

    .line {
      margin: 0;
      margin-bottom: 10px;
      width: 100%;
    }

    div.payment-figure {
      h4 {
        margin: 0;
        font-size: 18px;
      }
      span {
        padding-right: 0;
        font-size: 18px;
      }
    }

    div.payment-figure-FinalCountdown {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      margin-top: 20px;

      h3.cost {
        color: $kkUltraViolet;
        margin: 0;
        font-size: 40px;
        background: -webkit-linear-gradient($kkUltraViolet, $kkUltraViolet);
        // For some reason the text is coloured using background here for the Order Total on the shipping page
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 800;
      }

      span {
        color: $grey;
        font-size: 12px;
      }
    }

    div.all-shipping {
      display: flex;
      flex-direction: column;
      width: auto;
    }

    .box-rate {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      h4 {
        color: $kkUltraViolet;
        font-size: 45px;
        margin: 0;
      }

      span {
        margin-left: 5px;
        margin-bottom: 15px;
        font-size: 20px;
        color: $grey;

        &.noBox {
          margin-left: 0;
          text-align: left;
          font-size: 40px;
          color: $greySuperLight;
        }
      }
    }

    label.title-label {
      margin: 0;
      color: $kkUltraViolet;
      font-weight: 700;
      text-align: left;
      border: none;
    }

    p {
      text-align: left;
      margin: 0;
      margin-bottom: 10px;
      margin-right: 20px;
      color: $grey;
      font-size: 12px;
    }

    section.all-cost {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 5px 20px;

      div.item-summary {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 10px;

        div.each-box {
          margin-bottom: 1em;
        }

        div.each-title {
          h4 {
            margin: 0;
            text-align: left;
          }
        }

        div.each {
          display: flex;
          justify-content: space-between;

          span.box-name {
            text-align: left;
            justify-self: left;
            width: 100%;
          }

          span {
            &:first-child {margin-right: 5px; color: $grey; text-align: left;}
            &:last-child {margin-left: 5px; font-weight: 600;}
          }


        }
      }
    }

    section.total {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 5px 20px;

      div.cost-summary {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 10px;

        div.each {
          display: flex;
          justify-content: space-between;

          span {
            &:first-child {margin-right: 5px; color: $grey; text-align: left;}
            &:last-child {margin-left: 5px; font-weight: 600;}
          }
        }
      }
    }

    section.bottom-row {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 5px 20px;

      div.line {
        margin-bottom: 10px;
      }

      button.smallButton {
        margin: 0;
      }
    }

    @media (max-width: 1000px) {
      max-width: none;

      section.all-cost-and-total {
        display: flex;
        //width: 100%;
        justify-content: center;
        align-items: flex-start;

        section {
          width: 100%;
        }
      }
    }

    @media (max-width: $phone) {

      section.all-content {
        div.title {
          h2.blue-black-title {
            font-size: 20px;
          }
        }
        div.all-shipping {
          div.box-rate {
            h4 {
              font-size: 25px;
            }
            span {
              margin-bottom: 5px;
            }
          }
        }
      }

      section.all-cost-and-total {
        display: block;

        section {
          width: inherit;
        }
      }
    }
  }

  div.payment-figure-storageRequired {
    @media (max-width: $phone) {
      position: fixed;
      bottom: 0;
      margin: 0 auto;
      width: 100%;
      background: $kkUltraViolet;
      color: $white;
      align-items: center;
      padding: 10px 0;

      h4 {
        font-size: 12px;
        font-weight: 500;
      }

      span {
        &.cost {
          color: $white;
          font-size: 8vw;
        }
      }
    }
  }

  //Collection.tsx and Delivery.tsx
  div.all-coll-del {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    div.coll-del-div {    // address lookup and delivery date boxes
      width: 400px;
      position: relative;
      border: 2px solid $greyLighter;
      box-shadow: 1px 1px 10px 2px $greyShadow;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      margin: 50px 20px;
      height: 500px;

      @media (max-width: $phone) {
        height: auto;
        min-height: 400px;
      }

      p.grey {
        margin: 0;

        span {
          color: $kkUltraViolet;
        }
      }

      span.each-tick {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        label {
          margin: 0 10px;
        }
        input[type=checkbox] {
          width: 18px !important;
          height: 18px !important;
        }
      }

      div.title-and-input {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
        margin: 20px;

        div.title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 2px solid $greyLighter;
          padding: 10px 0;
          margin-bottom: 20px;
          width: 100%;

          img {
            width: 30px;
          }
        }

        .DayPickerInput {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        input.lookup, .DayPickerInput input {
          width: 98%;
          height: 40px;
          border: 1px solid $greyLighter;   // was greylighter  BORDER FOR THE ADDRESS OR DATE ENTRY
          background-color: $greyLighter;
          border-radius: 5px;
          box-shadow: 1px 1px 10px 2px #dedede inset;   // BOX SHADOWING OF ADDRESS OR DATE ENTRY
          font-size: 14px;

          &:focus {
            outline-color: $greySuperLight;
            border: 1px solid $greySuperLight;
          }
        }
      }

      div.description {
        margin: 20px;
      }
    }

    @media (max-width: $phone) {
      div.coll-del-div {
        width: 100%;
      }
    }
  }
}

@keyframes effect {
  from {
      background-position: 0;
  }
  to {
      background-position: -200% center;
  }
}

// Confirmation.tsx
div.all-cost, div.all-order {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 30px 0;

  @media(max-width: $phone) {
    margin: 30px 10px;
  }

  span {
    color: $kkUltraViolet;  // was $RoyalBlue
    font-weight: 700;
    text-align: left;
  }

  div.cost-total, div.order-total {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $greyLighter;

    &:nth-child(2) {
      border-top: 1px solid $greyLighter;
    }
  }

  div.cost-total {
    padding: 10px 0;

    h3 {
      font-size: 25px;
      margin: 0;
    }

    h4 {
      font-size: 22px;
      margin: 0;
      text-align: left;
    }
  }

  div.order-total {
    padding: 5px 0;

    h3 {
      font-size: 18px;
      margin: 0;
    }

    h4 {
      color: $grey;
      font-weight: 500;
      font-size: 18px;
      margin: 0;
      text-align: left;

      &.total {
        font-style: italic;
        font-weight: 600;
        color: #373737;
      }
    }
  }
}

div.all-address {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 30px 0;

  div.address-individual {
    width: 100%;
    max-width: 400px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    overflow: hidden;

    margin: 0 20px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    div.title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $greyLighter;
      padding: 10px 0;

      span {
        color: $kkUltraViolet;  // was $RoyalBlue
        font-weight: 700;
      }

      img {
        width: auto;
        height: 25px;
      }
    }

    div.full-address {
      display: flex;
      flex-direction: column;
      margin: 30px 0;
      width: 100%;

      span {
        color: #6b6b6b;
        text-align: left;
        margin: 5px 0;
      }
    }
  }

  @media(max-width: $phone) {
    flex-direction: column;
    padding: 10px;

    div.address-individual {
      margin: 0;

      &:first-child {
        margin-right: 0;
      }
      &:last-child {
        margin-left: 0;
      }

      div.title {
        margin: 0;
        width: auto;
      }

      div.full-address {
        margin: 20px 0;
      }
    }
  }
}

div.nextBtnInConfirmation {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

div.login {
  display: flex;
  border-top: 1px solid $grey;
  justify-content: space-between;
  align-items: center;

  div {
    margin-right: 15px;
  }

  h2 {
    font-size: 22px;
    text-align: left;
    margin: 10px 0;
  }

  span {
    display: block;
    text-align: left;
    margin: 10px 0 10px 0;
    font-weight: 700;
  }

  button {
    margin-top: 20px;
    margin-bottom: 20px;

    div {
      display: flex;
      align-items: center;
      margin: 0;
    }

    img {
      margin-left: 0;
      margin-right: 10px;
    }
  }

  @media(max-width: $phone) {
    margin: 10px;
  }
}

// Payment.tsx
form.payment {

  @media (max-width: $phone) {
    width: 100%;
    margin: 10px;
  }

  button {
    width: auto;
    padding: 20px;
    height: 60px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      &.payment-btn {
        min-width: 12em;
      }
    }
  }

  div.selection-a {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    button {
      background-image: none;
      background: $greyShadow;
      cursor: pointer;
      font-weight: 600;
      color: $kkUltraViolet;    // CONFIRM PAYMENT BUTTON   $royalblue
      margin: 5px 20px;
      border: none;
      padding: 5px 10px;
      height: auto;
      text-transform: none;
      text-decoration: underline;

      &:hover {
        color: $kkUltraVioletDark;
        text-decoration: none;
      }
    }
  }

  section.content {
    display: flex;
    flex-direction: row;

    @media (max-width: $phone) {
      flex-direction: column;
      width: 100%;
    }

    section.all-details {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 700px;
      width: 100%;
      padding: 20px;
      margin-right: 40px;

      @media (max-width: $midTablet) {
        max-width: 550px;
        margin-right: 5px;
        padding: 20px 10px;
      }
      @media (max-width: $phone) {
        max-width: 100%;
        width: 100%;
      }

      div.address {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        max-width: 95vw;
      }

      div.all-coll-del {

        div.title {
          border: none;
          margin: 0;
          padding: 0;
          img {
            display: none;
          }
        }

        input {
          background: transparent;
          box-shadow: none;
          border: none;
          border-bottom: 1px solid $greySuperLight;
          border-radius: 0;
          height: 20px;
        }

        div.coll-del-div {
          border: none;
          box-shadow: none;
          margin: 20px 0;
          padding: 0;
          height: auto;
          width: 100%;

          div.title-and-input {
            @media (max-width: $phone) {
              margin: 20px 0;
            }

            input.lookup {
              width: 99%;
            }
          }
        }
      }
    }

    section.all-order {
      width: 25em;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 20px;

      @media (max-width: $midTablet) {
        margin: 0;
        padding: 20px 10px;
      }
      @media (max-width: $phone) {
        width: 100%;
        max-width: 95vw;
      }

      span {
        width: 100%;
        color: $kkUltraViolet;
        font-weight: 700;
        text-align: left;
        border-bottom: 1px solid $greySuperLight;
        margin-bottom: 20px;
      }

      div.order-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
      }

      div.order-total {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $greySuperLight;
        padding: 5px 0;

        h4 {
          margin: 0;
          font-size: 14px;
          font-weight: 400;
          color: $grey;
          text-align: left;
          margin-right: 30px;
        }
        h3 {
          margin: 0;
          font-size: 14px;
          text-align: right;
          min-width: 100px;
        }
        .totalAmount {
          font-size: 20px;
          background: -webkit-linear-gradient($kkUltraViolet, $kkUltraVioletLight);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 800;
        }
        .totalName {
          font-weight: 600 !important;
        }
      }

      div.amount {
        div.order-total {
          h4 {
            color: $black;
            font-weight: 500;
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }

      div.discount-info {
        display: flex;
        flex-direction: column;
        width: 100%;

        input#discount, textarea {
          width: 100%;
          margin-right: 10px;
          border: 1px solid $greySuperLight;
          padding: 0 10px;
          border-radius: 0 !important;
        }

        &.note {
          margin-top: 2em;

          textarea {
            width: auto;
            margin-right: 0;
            height: 10em;
          }
        }

        div.input-and-btn {
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          button {
            margin: 0;
            height: 50px;
            padding: 10px 5px;
            min-width: 110px;   // was 90 but the new font meant I had to up it to 110 so that "SUBMIT" stayed on the button on the payment.tsx page
          }
        }
      }
    }
  }
}

div.payment {
  width: 100%;
  max-width: 95vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0;

  .each {
    width: 100%;
  }

  div.card-info {
    border: 2px solid $greyLighter;
    box-shadow: 1px 1px 10px 2px $greyShadow;
    width: 99%;
    border-radius: 10px;

    div.title-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f4f4f4;
      padding: 20px;
      border-bottom: 1px solid $white;

      h5 {
        margin: 0;
        font-size: 16px;
        font-weight: 700;
      }

      img {
        width: 40px;
        height: auto;
      }
    }

    div.input-bar {
      display: flex;
      flex-wrap: wrap;
      padding: 20px;
      background: #f4f4f4;

      div.each-tgt {
        display: flex;
        width: 100%;

        div.each {
          width: 100%;

          &:first-child {
            margin-right: 10px;
          }
          &:last-child {
            margin-left: 10px;
          }
        }
      }

      div.each {
        display: block;
        width: 100%;
        margin: 10px 0;
        text-align: left;

        section {
          border: 1px solid $greySuperLight;
          border-radius: 3px;
          background: $white;
          padding: 15px;
        }

        input {
          border: none;
          width: 95%;
          font-family: 'Montserrat', sans-serif;

          &::placeholder {
            color: $grey;
            font-size: 14px;
            font-weight: 500;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  label {
    margin-bottom: 10px;
  }

  #cardHolderName {
    border: none;
    outline: none;
  }

}

form.update-payment {
  max-width: 1400px;
  width: 100%;
  section.content {
    section.all-details {
      max-width: none;
      margin-right: 0;
      display: flex;
      flex-direction: row;

      div.address {
        div.addressLookup {
          width: 100%;
          div.full-address-info {
            div.each {
              width: 47%;

              @media (max-width: $phone) {
                width: 100%;
              }
            }
          }
        }
      }
      div.payment {
        display: flex;
        align-items: stretch;
        margin: 50px 20px;
        padding: 0;
        div.card-info {
          height: 100%;
          background: #f4f4f4;
        }
      }

      @media (max-width: $tablet) {
        flex-direction: column;
        div.payment {
          width: auto;
        }
      }
    }
  }
  h5.updateComplete {
    margin: 0;
    color: #3bad80;
    font-size: 18px;
    font-weight: 400;
  }
  @media (max-width: $phone) {
    div.addressLookup, div.payment {
      margin: 30px 10px !important;
    }
  }
}

// Login.tsx and Register.tsx

div.log-and-reg-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;

  @media (max-width: $phone) {
    width: 100%;
  }

  div.log-and-reg-content {
    margin: 10px;
    width: 100%;

    div.form-outer {
      margin: 10px auto;
    }
  }

  div.close-btn-div {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    button.close-btn {
      padding: 0;
      min-width: 0;
      height: auto;
      background: transparent;
      color: $grey;
      margin: 0;
      border: 0;
      border-radius: 3px;

      &:hover {
        color: $black;
      }

      i {
        font-size: 35px;
      }
    }
  }
}


form.log-and-reg {
  border: 1px solid $greySuperLight;
  box-shadow: 1px 1px 10px 2px $greyShadow;
  border-radius: 10px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  max-width: 500px;
  margin: 10px;

  button {
    img {
      margin-right: 10px;
    }
  }

  a {
    color: $kkUltraViolet;
    font-size: 14px;
    transition: 0.3s ease all;

    &:hover {
      color: $black;
    }
  }

  span.successMessage {
    color: $black;
    font-size: 14px;
  }

  div.form-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    div.each {
      display: block;
      width: 100%;
      margin: 10px 0;
      text-align: left;

      section {
        border: 1px solid $greySuperLight;
        border-radius: 4px;
        background: $white;
        display: flex;
        overflow: hidden;
      }

      input {
        border: none;
        width: 100%;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px !important;
        font-weight: 500;
        padding: 15px 10px;

        &::placeholder {
          color: $grey;
          font-size: 14px;
          font-weight: 500;
        }

        &:focus {
          outline: none;
        }
      }

      &.each-radio {
        section {
          padding: 1em 0.5em;
          flex-wrap: wrap;
        }
      }

      div.radio-container {
        input[type='radio'] {
          width: auto;
        }
        width: 100%;
      }
    }
  }
}

// Success.tsx

div.success {
  display: flex;
  flex-direction: column;
  padding: 50px 0;

  h2 {
    // font-family: 'Akira Expanded', sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 40px;
    color: #000000;
    margin: 0;

    &.blue-h2 {
        color: $RoyalBlue;
    }

    &.kkUltraViolet-h2 {
      font-family: 'Akira Expanded', sans-serif;  // make it Akira
      color: $kkUltraViolet;
    }
    // adding kk colours

    &.kkUltraViolet-h2-Monserrat {
      font-family: 'Montserrat', sans-serif;
      color: $kkUltraViolet;
    }


    span {
        color: $RoyalBlue;
    }
  }

  h3 {
    // font-family: 'Akira Expanded', sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 40px;
    color: #000000;
    margin: 0;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

  }

    span {
      margin: 20px 0;

      a {
          color: $RoyalBlue;
          font-weight: 700;
          text-decoration: none;
          border-bottom: 2px dotted $RoyalBlue;
      }
  }
}

//ADMIN STUFF

section.login-status-bar {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 1250px;

  div.status-bar-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0 20px;

    span {
      margin-right: 10px;
    }

    a {
      margin-right: 10px;
      color: $kkUltraViolet;

      &:hover {
        color: $black;
      }
    }
  }
}

section.nav-status-bar {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 1250px;

  div.status-bar-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 20px;

    i {
      margin-right: 10px;
    }

    button {
      margin-right: 10px;
    }
  }
}

section.search-bar {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  button, input {
    margin: 5px;
  }

  button {
    font-size: 12px;
    text-transform: none;
  }

  input.search-bar {
    width: 17rem;
    background: #F2F1F9;
    border: none;
    border-radius: 3px;
    padding: 0.5rem;

    &:focus {
      color: $RoyalBlueDarker;
    }
  }
}

//Admin/Dashboard.tsx
div.dashboard-all-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px;
  overflow: hidden;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: $phone) {
    flex-direction: column;
    margin: 20px;
  }

  section.each-box {
    background-image: none;
    background: #f5f5f5;
    max-width: 250px;
    width: 100%;
    border: 1px solid $greyLighter;
    box-shadow: 1px 1px 10px 2px $greyShadow;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 20px;
    padding-bottom: 60px;
    flex-basis: 50%;
    height: 300px;

    div.content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px 20px;

      img {
        width: auto;
        height: 60px;
      }

      h3 {
        font-size: 20px;
        color: #1a1a1a;
      }

      div.jessieJ-priceTag {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        border: 1px solid $grey;
        border-radius: 25px/50%;
        width: 85%;
        margin: 20px 0;
        color: $RoyalBlue;
        font-size: 30px;
        font-weight: 600;
        min-height: 40px;

        @media(max-width: $midTablet) {
          font-size: 20px;
        }

        @media(max-width: $largeTablet) and (orientation: landscape) {
          font-size: 20px;
        }
      }

      p {
        font-size: 14px;
        margin: 20px 0;
      }

      ul {
        padding-inline-start: 0;
        display: flex;
        flex-direction: column;
        width: 100%;

        li {
          list-style: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
          border-bottom: 1px solid $greySuperLight;

          span {
            margin-right: 40px;
            font-weight: 600;
            text-align: left;
          }

          img {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    button {
      position: absolute;
      bottom: 0;
      margin: 0 auto;
      width: 100%;
      text-transform: none;
      font-weight: 600;
      font-size: 16px;
      min-height: 60px;
      transition: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    div.dashboard-btn {
      padding: 5px 20px;
      background: #18191a;
      color: $white;
      border-radius: 3px;

      a {
        color: $white;
        text-decoration: none;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1.5px;
        transition: all 0.5s linear;
      }
    }

    &:hover {
      background-image: linear-gradient(45deg, $RoyalBlueDarker 15%, $RoyalBlue 65%);
      margin-top: 0;
      transition: all 0.5s ease-in-out;

      div.content {
        h3, p, span, div.jessieJ-priceTag {
          color: $white;
        }

        div.jessieJ-priceTag {
          border: 1px solid $RoyalBlueDarker;
        }

        ul {
          li {
            border-bottom: 1px solid $RoyalBlueDarker;
            img {
              width: 15px;
              height: 15px;
            }
          }
        }

        img {
          &.static-state {
            display: none;
          }
          &.rollover-state {
            display: block;
          }
        }
      }

      button {
        background: $white;
        color: $RoyalBlue;
      }

      div.dashboard-btn {
        background: $white;
        color: $black;

        a {
          color: $black;
          letter-spacing: 3px;
        }
      }
    }
  }
  section.each-box {
    div.content {
      h3, p, span {
        color: #1a1a1a;
      }

      div.jessieJ-priceTag {
        border: 1px solid $grey;
      }

      img {
        &.static-state {
          display: block;
        }
        &.rollover-state {
          display: none;
        }
      }
    }

    button {
      background-image: linear-gradient(45deg, $RoyalBlueDarker 15%, $RoyalBlue 65%);
      color: $white;

      &:hover {
        background: $white;
        color: $RoyalBlue;
      }
    }
  }
}

div.admin-dashboard {
  section.each-box {
    max-width: 450px;

    button {
      left: 0;
    }
  }
}

div.customer-dashboard {
  section.each-box {
    height: 250px;
    cursor: pointer;
  }
  @media (max-width: $phone) {
    width: 100%;
    flex-direction: column;
    section.each-box {
      max-width: 400px;
      width: 90%;
      margin: 10px 0;
      height: auto;
      div.content {
        padding: 30px 10px 10px 10px;
      }
      button.submitButton {
        min-height: 30px;
      }
    }
  }
}

section.showMoreBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  button.submitButton {
    width: 100%;
    max-width: 500px;
    background: #373737;
    color: $white;
    margin: 0 10px;

    &:hover {
      background: #171717;
      color: $RoyalBlue;
    }

    i {
      font-size: 21px;
    }

    span {
      margin-right: 10px;
    }
  }
}

section.admin-all-orders-info,
section.admin-all-customers-info,
section.admin-all-products-info,
section.admin-all-discounts-info,
section.suitcase-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .button-div {
    display: grid;
    place-items: center;
  }

  h3 {
    text-align: left;
    font-size: 25px;
    margin: 0;
  }

  div.title {
    display: flex;
    flex-direction: column;
    margin: 20px 50px;
  }

  div.table {
    margin: 50px;
    //border: 2px solid $black;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    text-align: left;
    border-collapse: collapse;
    display: flex;
    flex-direction: column;

    .th, .td {
      padding: 12px 15px;
    }
  }

  label.title-label {
    display: none;
  }

  .tr {
    display: flex;
    width: 100%;
  }

  .td {
    float:left;/*fix for buggy browsers*/
    display:table-column;
    width: 100%;
  }

  .id {
    width: 2%;
  }

  .yes-and-no {
    width: 5%;
  }

  .date {
    width: 20%;
  }

  .address {
    width: 20%;
  }

  .thead {
    display: flex;
    flex-direction: column;
    width: 100%;
    .tr {
      background-color: $RoyalBlue;
      color: #ffffff;
      display: flex;
      align-items: center;
      font-weight: 600;

      .td {
        padding: 5px 20px;
      }
    }
  }

  .tbody {
    display: flex;
    flex-direction: column;
    width: 100%;
    .tr{
      width: 100%;
      border: none;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
      display: flex;

      &:nth-child(even) {
        background: #f3f3f3;
      }

      &:last-of-type {
        border-bottom: 5px solid $RoyalBlue !important;
      }

      &:hover {
        cursor: pointer;
        background: $RoyalBlueDarker;
        color: $white;
        border-top: 1px solid $RoyalBlue;
        border-bottom: 1px solid $RoyalBlue;
      }

      .td {
        padding: 5px 20px;
        a {
          color: $RoyalBlue;

          &:hover {
            text-decoration: none;
          }
        }
      }

      button {
        min-width: 0;
        outline: none;
        cursor: pointer;
        &.trash {
          background: transparent;
          min-width: 0;
          padding: 0;
          height: auto;
          border: none;

          &:hover {
            i { color: $CherryRed; }
          }

          i {
            color: $grey;
            font-size: 1.5em;
          }
        }
      }
    }
  }

  div.discount-button-wrapper {
    button.submitButton {
      width: 8em;
      padding: 5px 10px;
      height: auto;
      text-transform: none;
      font-size: 1em;
      i {
        margin-right: 5px;
        font-size: 0.8em;
      }
    }
  }

  @media (max-width: $tablet) {

    label.title-label {
      display: block;
      margin: 5px 20px;
      height: auto;
      color: $RoyalBlue;
      font-weight: 700;
      border-bottom: 1px solid $greySuperLight;
      text-align: left;
    }

    div.table {
      flex-direction: row;
      //overflow: visible;
    }

    .tr {
      flex-direction: column;
      width: auto !important;
      padding: 10px;
      align-items: flex-start !important;
    }

    .td {
      flex-direction: column;
      width: auto;
      padding: 10px;
    }

    .id, .yes-and-no, .date, .address {
      width: auto;
    }

    .thead, .tbody {
      width: 100%;
    }

    .thead {
      display: none;
    }
    .tbody {
      width: 100%;
    }
  }

  @media(max-width: $phone) {
    div.table {
      margin: 20px;
    }
  }
}

section.admin-all-customers-info {
  .table {
    .thead, .tbody {
      .tr {
        .id { width: 2%};
        .phone { width: 20% !important;};
        .name { width: 30% !important;};
        .email { width: 40% !important;};

        @media (max-width: $phone) {
          .id { width: 90% };
          .phone { width: 90% !important;};
          .name { width: 90% !important;};
          .email { width: 90% !important; word-wrap: break-word;};
        }
      }
    }
  }
}

section.admin-all-discounts-info {
  .table {
    .thead, .tbody {
      .tr {
        .xs { width: 2% !important; }
        .small { width: 5% !important; }
        .large { width: 20% !important; }
        .mid {width: 15% !important ;}
        .code {
          word-wrap: break-word;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        @media (max-width: $tablet) {
          .xs, .small, .large, .mid { width: 90% !important; }
          .code { word-wrap: initial; }
        }
      }
    }
  }

  span {
    font-weight: 500;
    &.inactive {
      color: $CherryRed;
    }
    &.active {
      color: #5ac295;
    }
  }
}

section.admin-all-products-info {
  .table {
    .thead, .tbody {
      .tr {
        .small { width: 3% !important; };
        .large { width: 20% !important; };
        .mid {width: 15% !important ;};
      }
    }
  }
  .img {
    img {
      max-width: 30px;
    }
  }
}


section.all-orders-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 50px;

  @media (max-width: $phone) {
    padding: 20px;
  }

  .each-order {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 500px;
    width: 100%;
    margin: 20px;
    box-shadow: 1px 1px 10px 2px $greyShadow;

    p {
      margin: 0;
    }

    .top-border {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      background-image: linear-gradient(45deg, $RoyalBlueDarker 15%, $RoyalBlue 65%);
      color: white;
      font-weight: 600;
      font-size: 20px;
    }

    .second-border {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background: #fafafa;
      padding: 5px 0;

      p {
        color: grey;
        border-right: 1px solid $greySuperLight;
        padding: 0 10px;

        &.roomPack {
          font-weight: 700;
        }
        &.date {
          font-style: italic;
          font-size: 12px;
          border: none;
        }
      }
    }

    label.title-label {
      width: 100%;
      margin: 0;
      height: auto;
      color: $RoyalBlue;
      font-weight: 700;
      border-bottom: 1px solid $greySuperLight;
      text-align: left;
    }

    .content {
      margin-top: 20px;

      .inner-line {
        height: 7px;
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
        width: auto;
        margin: 10px 30px;
      }

      .id-and-city {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 30px;

        p {

          &.id {
            font-size: 30px;
            font-weight: 700;
          }

          &.city {
            font-size: 35px;
            font-weight: 600;
            color: $RoyalBlueDarker;
            text-align: left;
            @media(max-width: $phone) {
              font-size: 25px;
            }
          }
        }
      }

      .order-date,
      .date-and-address,
      .order-details,
      .box-details,
      .cost-details {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 10px 30px;
      }

      .details {
        margin: 10px 0;
        border-left: 3px solid $RoyalBlueDarker;
        padding-left: 17px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;

        label.title-label {
          width: auto;
          margin-top: 10px;
          color: $RoyalBlueDarker;

          &:first-child {
            margin-top: 0;
          }
        }
        @media(max-width: $phone) {
          padding-left: 5px;
          label.title-label {
            font-size: 14px;
          }
          p, p span {
            font-size: 12px;
          }
        }
      }
    }

    .all-buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 10px 50px;

      button {
        margin: 5px;

        &:nth-child(3) {
          background: $grey;
        }
      }
    }

    div.orderDates {
      text-align: left;
      margin: 10px;

      p.updatedAt {
        font-size: 12px;
        font-style: italic;
        color: $grey;
      }

      div.line {
        width: 100%;
        margin: 0;
        margin-bottom: 5px;
      }
    }

    @media (max-width: $phone) {
      margin: 10px;

      .inner-line {
        margin: 10px !important;
      }
      .id-and-city, .order-date, .date-and-address, .order-details, .cost-details {
        padding: 5px 10px !important;
      }
    }
  }
}

div.print-doc {
  display: flex;
  flex-direction: column;
  width: 95%;
  border: 3px solid $black;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin: 20px;

  .top-border {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background-image: linear-gradient(45deg, $RoyalBlueDarker 15%, $RoyalBlue 65%);
    color: white;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 1.5px;
  }

  .second-border {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    background: #fafafa;
    padding: 5px 0;

    p {
      color: grey;
      border-right: 1px solid $greySuperLight;
      padding: 0 10px;

      &.roomPack {
        font-weight: 700;
      }
      &.date {
        font-style: italic;
        font-size: 12px;
        border: none;
      }
    }
  }

  p {
    margin: 0;
  }

  label.title-label {
    width: 100%;
    margin: 0;
    height: auto;
    color: $RoyalBlue;
    font-weight: 700;
    border-bottom: 1px solid $greySuperLight;
    text-align: left;
  }

  .id-city-requirement {
    display: flex;

    @media (max-width: $largeTablet) {
      flex-direction: column;
    }
  }

  .id-and-city {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 50px 0 50px 50px;
    min-width: 200px;

    @media (max-width: $phone) {
      padding: 10px;
    }

    p {
      text-align: left;

      &.id {
        font-size: 50px;
        font-weight: 700;
      }

      &.city {
        font-size: 40px;
        font-weight: 600;
        color: $RoyalBlueDarker;
        text-align: left;
        @media(max-width: $phone) {
          font-size: 25px;
        }
      }
    }

    div.name {
      margin-top: 10px;
      border-top: 3px double $RoyalBlueDarker;
      border-bottom: 3px double $RoyalBlueDarker;
      margin-bottom: 3px;
      cursor: pointer;

      p {
        font-size: 25px;
      }

      &:hover {
        background: $RoyalBlue;
        border-top: 3px double $white;
        border-bottom: 3px double $white;
        p { color: $white };
      }
    }
  }

  .basic-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $phone) {
      flex-direction: column;
    }
  }

  .requirements-block {
    display: flex;
    flex-direction: column;
    padding: 50px;

    @media (max-width: $phone) {
      padding: 0 20px;
    }

    .requirements {
      max-width: 250px;
      border: 1px solid $black;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      label {
        width: auto;
      }

      p {
        text-align: left;
        margin: 5px 0;

        span {
          font-weight: 600;
          color: $RoyalBlueDarker;
        }
      }
    }
  }

  .addresses-block {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 50px;
    padding-left: 0;

    @media(max-width: $tablet) {
      flex-direction: column;
    }

    @media (max-width: $phone) {
      padding: 10px;
    }

    .each {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 10px;
      margin-left: 30px;

      label {
        text-align: right;
        width: auto;
      }

      p {
        text-align: right;
        margin: 10px 0;
      }

      @media (max-width: $phone) {
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 0;
        padding: 10px 0;

        p {
          text-align: left;
          font-size: 14px;
        }
      }
    }
  }

  .items-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;

    div.table {
      margin: 20px 0;
      border-radius: 10px 10px 0 0;
      overflow: hidden;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
      text-align: left;
      border-collapse: collapse;
      display: flex;
      flex-direction: column;

      @media(max-width: $phone) {
        margin: 20px 10px 50px 10px;
      }

      .th, .td {
        padding: 12px 15px;
      }

      img {
        width: 30px;
      }
    }

    label.title-label {
      display: none;
    }

    .tr {
      display: flex;
      width: 100%;
    }

    .td {
      float:left;/*fix for buggy browsers*/
      display:table-column;
      width: 100%;

      &.img {
        width: 30%;
      }

      &.name {
        width: 50%;
      }

      &.code {
        width: 150%;
      }
    }

    .thead {
      display: flex;
      flex-direction: column;
      width: 100%;
      .tr {
        background-color: $RoyalBlue;
        color: #ffffff;
        display: flex;
        align-items: center;
        font-weight: 600;
        min-height: 60px;

        .td {
          padding: 5px 20px;
        }
      }
    }

    .tbody {
      display: flex;
      flex-direction: column;
      width: 100%;
      .tr{
        padding: 10px 0;
        width: 100%;
        border: none;
        border-top: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        display: flex;

        &:nth-child(even) {
          background: #f3f3f3;
        }

        &:last-of-type {
          border-bottom: 5px solid $RoyalBlue !important;
        }

        &:hover {
          cursor: pointer;
          background: $RoyalBlueDarker;
          color: $white;
          border-top: 1px solid $RoyalBlue;
          border-bottom: 1px solid $RoyalBlue;

          button {
            border: none;
            padding: 5px;
            &.trash {
              i {
                color: $black !important;
              }
            }
          }
        }

        .td {
          padding: 5px 20px;
          a {
            color: $RoyalBlue;

            &:hover {
              text-decoration: none;
            }
          }

          button {
            min-width: 0;
            &.trash {
              background: transparent;
              min-width: 0;
              padding: 0;
              height: auto;
              border: none;

              i {
                color: $grey;
                font-size: 20px;
              }
            }
          }

          &.active {
            color: #3bad80;
          }
          &.inactive {
            color:#e05451;
          }
        }
      }
    }

    @media (max-width: $phone) {

      label.title-label {
        display: block;
        margin: 5px 20px;
        height: auto;
        color: $RoyalBlue;
        font-weight: 700;
        border-bottom: 1px solid $greySuperLight;
        text-align: left;
      }

      div.table {
        flex-direction: row;
        //overflow: visible;
      }

      .tr {
        flex-direction: column;
        width: auto !important;
        padding: 10px;
        align-items: flex-start !important;
      }

      .td {
        flex-direction: column;
        width: auto;
        padding: 10px;
      }

      .id, .yes-and-no, .date, .address {
        width: auto;
      }

      .thead, .tbody {
        width: 100%;
      }

      .thead {
        display: none;
      }
      .tbody {
        width: 100%;
      }
    }

    @media(max-width: $phone) {
      div.table {
        margin: 20px 10px;
      }
    }

    label {
      margin-bottom: 20px;
    }

    @media (max-width: $phone) {
      padding: 0px;
    }

    .each {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 40%;
      max-width: 400px;
      margin: 5px;
      padding: 10px 20px;
      box-shadow: 1px 1px 10px 2px $greyShadow;

      @media (max-width: $midTablet) {
        width: 100%;
      }

      .img-and-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $RoyalBlue;
        width: 100%;
        padding: 10px 0;

        img {
          width: 15%;
          max-width: 20px;
        }

        p.orderName {
          text-align: right;
          font-size: 14px;
          font-weight: 600;
        }
      }

      p.barcode {
        margin-top: 10px;
      }

      .eachDetails {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 93%;
        border-left: 2px solid $RoyalBlueDarker;
        padding: 10px;
        margin: 20px 0;
        p {
          text-align: left;
          margin: 0;
        }
      }


      label {
        width: auto;
        color: $RoyalBlueDarker;
        margin-top: 10px;
        margin-bottom: 0;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .status-block,
  .payment-status-block,
  .note-block {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 50px;

    .each {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;

      border-left: 2px solid $RoyalBlueDarker;
      margin: 20px 20px 20px 0;
      padding: 10px;

      label.title-label {
        margin-top: 10px;
        width: auto;
        color: $RoyalBlueDarker;

        &:first-child {
          margin-top: 0;
        }
      }
    }
    @media (max-width: $phone) {
      padding: 20px;
      .each {
        margin: 5px 10px 5px 0;
        padding: 5px;
        label.title-label {
          font-size: 12px;
          font-weight: 500;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }

  .payment-block {
    display: flex;
    background: $RoyalBlue;
    padding: 20px 20px;
    justify-content: center;
    align-items: center;

    .divider {
      width: 1px;
      height: 40px;
      background: $RoyalBlueDarker;
    }

    .each {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;

      &:first-child {
        label {
          text-align: left;
        }
      }

      &:last-child {
        label {
          text-align: right;
        }
      }

      label.title-label {
        border: none;
        color: $greySuperLight;
        margin: 0 20px;
        letter-spacing: 1.5px;
        font-size: 20px;
      }

      h5 {
        color: white;
        margin: 0;
        font-size: 35px;
        font-weight: 700;
        text-shadow: 4px 2px $RoyalBlueDarker;
      }

      @media (max-width: $phone) {
        padding: 0 10px;

        label.title-label {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 1px;
          margin: 0 7px;
        }

        h5 {
          font-size: 18px;
          font-weight: 500;
          text-shadow: 2px 2px $RoyalBlueDarker;
        }
      }
    }
  }
}

section.print-doc-and-items-block {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 20px;

  @media(max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  div.admin-print-doc {
    margin: 0;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }

    @media(max-width: 1300px) {
      .id-and-city, .addresses-block, .status-block, .payment-status-block {
        padding: 20px;
      }
    }

    @media(max-width: 1100px) {
      margin: 10px 0;
      width: 95% !important;
    }
  }

  div.barcode-doc {
    display: flex;
    width: auto;
  }

  div.addresses-block {
    @media(max-width: 1400px) {
      flex-direction: column !important;
    }
  }
}

div.customer-print-doc {

  div.items-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    div.eachBox {
      max-width: 300px;
      width: 100%;
      align-self: stretch; //This to make flex same height
      display: flex;
      flex-direction: column;
      margin: 10px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

      @media (max-width: $phone) {
        max-width: none;
      }

      div.border-top {
        width: 100%;
        height: 3px;
        background-image: linear-gradient(45deg, $RoyalBlueDarker 15%, $RoyalBlue 65%);
        margin-bottom: 10px;
      }

      div.content {
        padding: 20px;

        div.basic {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          text-align: left;
          margin-right: 10px;

          h3 {
            color: $RoyalBlue;
            font-size: 30px;
            font-weight: 600;
            margin: 0;
            margin-top: 10px;
          }

        }
        @media(max-width: $phone) {
          padding: 10px;
          img.boxIcon {
            max-width: 50px;
          }
          div.basic {
            h3 {
              font-size: 20px;
            }
            p {
              font-size: 14px;
            }
          }
        }

        div.cost {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin: 10px 0;

          label.title-label {
            display: flex !important;
            margin: 0;
            margin-bottom: 5px;
          }

          .costDetails {
            margin: 10px 0;
            border-left: 3px solid $RoyalBlueDarker;
            padding-left: 17px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            text-align: left;
            width: calc(100% - 20px);

            div.suitcase-mobile-view-table {
              width: 100%;
            }

            label.title-label {
              width: auto;
              margin-top: 10px;
              color: $RoyalBlueDarker;

              &:first-child {
                margin-top: 0;
              }
            }
          }

          @media(max-width: $phone) {
            .costDetails {
              padding-left: 5px;
              margin: 5px 0;
            }
            label.title-label {
              font-size: 12px;
              font-weight: 500;
            }
            p {
              font-size: 14px;
            }
          }
        }
      }


      img.boxIcon {
        max-width: 100px;
        width: 70%;
      }
    }
  }
}

.btn-row {
  display: flex;

  button {
    margin: 0 10px;
  }
}

.new-discounts {
  width: 100%;
  margin: 50px 0;

  h3 {
    text-align: center;
    font-size: 25px;
    margin: 0;
  }

  .admin-dashboard {
    margin: 20px 0;
  }
}

.existing-discounts {
  max-width: 1400px;
  width: 100%;
  margin: 50px 0;

  h3 {
    text-align: left;
    font-size: 25px;
    margin: 0;
  }

  div.title {
    display: flex;
    flex-direction: column;
    margin: 20px 50px;
  }

  div.table {
    margin: 20px 50px 70px 50px;
    //border: 2px solid $black;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    text-align: left;
    border-collapse: collapse;
    display: flex;
    flex-direction: column;

    .th, .td {
      padding: 12px 15px;
    }
  }

  label.title-label {
    display: none;
  }

  .tr {
    display: flex;
    width: 100%;
  }

  .td {
    float:left;/*fix for buggy browsers*/
    display:table-column;
    width: 100%;
  }

  .small {
    width: 8%;
  }
  .mid {
    width: 10%;
  }
  .large {
    width: 20%;
  }

  .thead {
    display: flex;
    flex-direction: column;
    width: 100%;
    .tr {
      background-color: $RoyalBlue;
      color: #ffffff;
      display: flex;
      align-items: center;
      font-weight: 600;
      min-height: 60px;

      .td {
        padding: 5px 20px;
      }
    }
  }

  .tbody {
    display: flex;
    flex-direction: column;
    width: 100%;
    .tr{
      padding: 10px 0;
      width: 100%;
      border: none;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
      display: flex;

      &:nth-child(even) {
        background: #f3f3f3;
      }

      &:last-of-type {
        border-bottom: 5px solid $RoyalBlue !important;
      }

      &:hover {
        cursor: pointer;
        background: $RoyalBlueDarker;
        color: $white;
        border-top: 1px solid $RoyalBlue;
        border-bottom: 1px solid $RoyalBlue;

        button {
          &.trash {
            border: none;
            padding: 5px;
            i {
              color: $black !important;
            }
          }
        }
      }

      .td {
        padding: 5px 20px;
        a {
          color: $RoyalBlue;

          &:hover {
            text-decoration: none;
          }
        }

        button {
          min-width: 0;
          &.trash {
            background: transparent;
            min-width: 0;
            padding: 0;
            height: auto;
            border: none;

            i {
              color: $grey;
              font-size: 20px;
            }
          }
        }

        &.active {
          color: #3bad80;
        }
        &.inactive {
          color:#e05451;
        }
      }
    }
  }

  @media (max-width: $tablet) {

    label.title-label {
      display: block;
      margin: 5px 20px;
      height: auto;
      color: $RoyalBlue;
      font-weight: 700;
      border-bottom: 1px solid $greySuperLight;
      text-align: left;
    }

    div.table {
      flex-direction: row;
      //overflow: visible;
    }

    .tr {
      flex-direction: column;
      width: auto !important;
      padding: 10px;
      align-items: flex-start !important;
    }

    .td {
      flex-direction: column;
      width: auto;
      padding: 10px;
    }

    .id, .yes-and-no, .date, .address {
      width: auto;
    }

    .thead, .tbody {
      width: 100%;
    }

    .thead {
      // display: none;
    }
    .tbody {
      width: 100%;
    }
  }

  @media(max-width: $phone) {
    div.table {
      margin: 20px;
    }
  }

}

div.modal, div.ReactModal__Overlay {
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.25) !important;
  padding: 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 !important;

  @media (max-width: $phone) {
    padding: 0 5px !important;

    div.modal-dialog, div.ReactModal__Content {
      padding: 10px !important;
    }
  }

  div.modal-dialog, div.ReactModal__Content {
    display: flex;
    flex-direction: column;
    margin: 0 auto !important;
    inset: 0 !important;
    position: relative !important;
    max-width: 600px;
    width: 100%;
    top: 10px !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 10px !important;
    border-radius: 4px !important;
    overflow-x: hidden !important;

    form {
      width: 100%;
      display: flex;
      flex-direction: column;

      button {
        margin: 0;
      }
    }

    label.title-label {
      width: 100%;
      margin: 0;
      height: auto;
      color: $RoyalBlueDarker;
      font-weight: 700;
      border-bottom: 1px solid $greySuperLight;
      text-align: left;
    }

    div.modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      border-bottom: 1px solid $greySuperLight;

      h3 {
        margin: 0;
        margin-right: 10px;
      }

      button.red {
        min-width: 0;
        background: $CherryRed;
        color: $white;
        padding: 5px 15px;
        border-radius: 3px;
        text-transform: none;

        &:hover {
          background-image: linear-gradient(45deg, $CherryRedDarker 15%, $CherryRed 65%);
        }
      }

      span.discount-code {
        font-style: italic;
        color: #272727;
        font-weight: 600;
      }
    }

    div.modal-content {
      display: block;
      max-height: 60vh;
      padding: 20px 0;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-track {
          background: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
          background-image: linear-gradient(45deg, $RoyalBlue 0%, $RoyalBlueDarker, 75%, $RoyalBlueDarker 100%);
          border-radius: 1.5px;
      }

      input {
        font-size: 15px;
        font-weight: 500;
      }

      div.percentage {
        display: flex;
        flex-direction: column;
        margin: 0 0 20px 0;

        .stuff {
          display: flex;
          margin-top: 10px;

        }

        input[type=number] {
          width: 50px;
          height: 30px;
          padding-left: 5px;
          border: 1px solid $greySuperLight;
          box-shadow: 1px 1px 1px 1px $greyShadow inset;
          border-radius: 0 !important;

          &:disabled {
            box-shadow: none;
            background: #efefef;
            border: 1px solid #efefef;
            color: $grey;
          }
        }

        h6 {
          color: $RoyalBlue;
          font-size: 25px;
          margin: 0;
          margin-left: 10px;
        }
      }

      div.expires {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;

        .expiry-date-switch {
          margin: 10px 5px;
          display: flex;
          align-items: center;

          span.expiry-span {
            font-weight: 600;
            margin-left: 5px;
            margin-bottom: 5px;
            &.need { color: #5ac295; }
            &.no-need { color : #bababa; }
          }
        }

        .dateLookup {
          margin: 10px 0;
          .DayPickerInput-OverlayWrapper {
            z-index: 50 !important;
            min-height: 320px;
          }
        }

      }

      div.checkbox, div.cost {
        display: flex;
        flex-direction: column;
        margin: 0 0 20px 0;

        div.stuff {
          display: flex;
          flex-wrap: wrap;
          margin-top: 10px;

          .each {
            margin: 0 20px 20px 0;
            display: flex;
            align-items: center;

            input[type=number] {
              height: 35px;
              padding-left: 5px;
              border: 1px solid $greySuperLight;
              box-shadow: 1px 1px 1px 1px $greyShadow inset;
              margin: 0 5px 5px 0;
              width: 100px;
              border-radius: 0 !important;
            }

            label.toggle-switch-label {
              margin-bottom: -7px;
              margin-right: 5px;

              div.disabled-switch {
                opacity: 0.2 !important;
              }
            }

            label.title-label {
              color: $black;
              border: none;
              width: auto;
              font-weight: 600;

              &.disabled {
                color: $greySuperLight;
              }
            }
          }
        }
      }

      div.checkbox div.stuff {
        margin-left: 5px;
      }

      div.code,
      div.order-edit-inputs,
      div.user-edit-inputs {
        display: flex;
        flex-direction: column;
        margin: 0 5px 5px 0;

        div.stuff {
          display: flex;
          flex-wrap: wrap;
          margin-top: 10px;

          textarea {
            width: 100%;
            min-height: 100px;
            font-size: 14px;
            border: 1px solid $greySuperLight;
            box-shadow: 1px 1px 1px 1px $greyShadow inset;
            padding: 5px;
            border-radius: 0 !important;
          }

          input[type=text], input[type=number], .DayPickerInput input {
            height: 35px;
            padding-left: 5px;
            border: 1px solid $greySuperLight;
            box-shadow: 1px 1px 1px 1px $greyShadow inset;
            margin: 0 5px 5px 0;

            &:disabled {
              box-shadow: none;
              background: #efefef;
              border: 1px solid #efefef;
              color: $grey;
            }
          }

          input[type=text], input[type=number] {
            border-radius: 0 !important;
          }

          input[name=name] {
            max-width: 400px;
            width: 100%;
          }

          input[name=imageUrl] {
            width: 100%;
          }

          input[type=number] {
            width: 100px;
          }

          input[type=submit] {
            height: 39px;
            background: $RoyalBlueDarker;
            text-transform: uppercase;
            border: none;
            border-radius: 2px;
            transition: all 400ms linear;
            cursor: pointer;
            padding: 0 20px;
            color: $white;
            font-size: 18px;

            &:hover {
              background: $black;
            }
          }

          button {
            min-width: 0;
            height: 39px;
            background: $RoyalBlueDarker;

            &:hover {
              background: $black;
            }
          }
        }

        span {
          margin-top: 10px;
          color: $grey;

          &.error {
            margin: 0;
          }
        }
      }
    }

    div.modal-footer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      input[type=submit] {
        font-size: 18px;
        color: $white;
        background-color: $RoyalBlue;
        text-transform: uppercase;
        border: none;
        border-radius: 2px;
        transition: all 400ms linear;
        cursor: pointer;
        padding: 0 10px;
        height: 45px;
        margin: 0 5px 10px 5px;

        &:hover {
          background-image: linear-gradient(45deg, $RoyalBlueDarker 15%, $RoyalBlue 65%);
        }
      }

      button {
        margin: 0 5px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          background: $grey;
        }
      }
    }
  }
}

form.order-edit-form {
  width: 100%;
  display: flex;
  flex-direction: column;

  .error-block {
    margin: -20px 0 20px 0;
  }

  div.order-edit-inputs {

    span {
      font-size: 14px;
    }

    div.current-info {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      margin: 10px auto 20px 0;
      box-shadow: -1px -1px 10px -2px $greyShadow;

      div.current-label {
        //flex-basis: 20%;
        display: flex;
        justify-content: center;
        align-content: center;
        padding: 10px;
        border: 3px solid $white;
        border-left: 3px solid $black;

        h6 {
          align-self: center;
          text-transform: uppercase;
          font-style: italic;
          font-size: 12px;
          padding-right: 3px;
          margin: 0;
          font-weight: 700;
          background: linear-gradient(90deg, $RoyalBlue 0%, $RoyalBlueDarker, 75%, $RoyalBlueDarker 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      div.info {
        //flex-basis: 80%;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        padding: 10px;
        padding-left: 0;

        span {
          align-self: center;
          margin: 0;
          border-left: 1px solid $greySuperLight;
          padding-left: 5px;
        }
      }
    }

    div.stuff {
      input[type=text] {
        width: 100% !important;
        border-radius: 0 !important;
      }
      div.addressLookup {
        width: 100%;
      }
      span.error {
        width: 100%;
        padding-bottom: 5px;
        text-align: left;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  div.dateLookup {
    width: 100%;

    .DayPickerInput-OverlayWrapper {
      z-index: 50 !important;
      min-height: 320px;
    }
  }

  span.error {
    text-align: center;
    margin: 5px 0;
  }
}

form.user-edit-form,
form.personal-details-edit-form {
  width: 100%;
  display: flex;
  flex-direction: column;

  div.user-edit-inputs {
    div.stuff {
      div.each {
        display: flex;
        flex-direction: column;
        width: 49%;
        margin: 0 2% 10px 0;

        &:last-child {
          margin-right: 0;
        }

        @media(max-width: $phone) {
          width: 100%;
          margin: 0;
          margin-bottom: 10px;
        }

        input.name-input {
          margin: 0 !important;
        }
        label.user-edit-label {
          border-bottom: none;
          color: $grey;
          font-size: 12px;
        }
      }

      input.email-input {
        width: 100% !important;
      }
    }
  }
}

//DateLookup Component

div.dateLookup {
  position: relative;
  border: 2px solid $greyLighter;
  box-shadow: 1px 1px 10px 2px $greyShadow;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;

  div.title-and-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    margin: 20px;

    @media (max-width: $phone) {
      margin: 10px;
    }

    div.title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid $greyLighter;
      padding: 10px 0;
      margin-bottom: 20px;
      width: 100%;

      img {
        width: 30px;
      }
    }

    .DayPickerInput {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start !important;
      width: 100%;
      input {
        margin: 0 !important;
        margin-bottom: 5px !important;
      }
    }

    input.lookup, .DayPickerInput input {
      width: 98% !important;
      height: 40px;
      border: 1px solid $greyLighter;
      background-color: $greyLighter;
      border-radius: 5px !important;
      box-shadow: 1px 1px 10px 2px #dedede inset;
      font-size: 14px;

      &:focus {
        outline-color: $greySuperLight;
        border: 1px solid $greySuperLight;
      }
    }
  }
}

// AddressLookup Componenet
div.addressLookup {
  position: relative;
  border: 2px solid $greyLighter;
  box-shadow: 1px 1px 10px 2px $greyShadow;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;

  @media (max-width: $phone) {
    height: auto;
    min-height: 400px;
  }

  p.grey {
    margin: 0;

    span {
      color: $RoyalBlue;
    }
  }

  div.title-and-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    margin: 20px;

    @media (max-width: $phone) {
      margin: 10px;
    }

    div.title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid $greyLighter;
      padding: 10px 0;
      margin-bottom: 20px;
      width: 100%;

      img {
        width: 30px;
      }
    }

    input.lookup {
      width: 98%;
      height: 40px;
      border: 1px solid $greyLighter;
      background-color: $greyLighter;  //was $greyLighter
      border-radius: 5px;
      box-shadow: 1px 1px 10px 2px #dedede inset;
      font-size: 14px;

      &:focus {
        outline-color: $greySuperLight;
        border: 1px solid $greySuperLight;
      }
    }
  }

  div.description {
    margin: 20px;
  }

  div.address {
    z-index: 999;
    position: relative;
    width: 100%;
    margin: 0;

    ul {
      width: 99%;
      position: absolute;
      top: 0;
      padding-inline-start: 0;
      border: 2px solid $greyShadow;
      margin: 0 auto;
      max-height: 15em;
      overflow-y: scroll;
      box-shadow: 1px 1px 10px 2px $greyShadow;
    }

    li {
      list-style: none;
      padding: 10px 0;
      margin: 0;
      cursor: pointer;

      &:nth-child(even) {
        background-color: $greyShadow;
      }

      &:nth-child(odd) {
        background-color: $white;
      }
    }
  }

  div.full-address-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0;
    width: 100%;

    div.each {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 45%;
      margin: 10px 5px;

      input {
        display: flex;
        border: none;
        width: 95%;

        &:focus {
          outline: none;
        }

        @media (max-width: $phone) {
          width: 98%;
        }
      }

      div.line {
        margin: 0 !important;
        width:100%;
        height: 1px;
        background: $greySuperLight;
      }

      span {
        color: $grey;
        font-size: 12px;
        font-weight: 600;
      }

      @media (max-width: $phone) {
        width: 100%;

        input {
          font-size: 16px;
        }
      }
    }
  }
}

section.option-box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1500px;
  width: 100%;

  .each-box {
    max-width: 300px;
    width: 100%;
    padding: 20px 10px;
    border: 1px solid $greySuperLight;
    border-radius: 3px;
    margin: 20px;
    display: flex;
    cursor: pointer;

    &:hover {
      border: 1px solid $black;
      background-image: linear-gradient(45deg, $RoyalBlueDarker 15%, $RoyalBlue 65%);

      div.content {
        h5 {
          color: $white;
        }

        p {
          color: $greySuperLight;
        }
      }
    }

    div.img {
      flex-basis: 15%;
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 30px;
      }
    }

    div.content {
      flex-basis: 85%;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;

      h5 {
        font-size: 18px;
        margin: 0;
        margin-bottom: 10px;
        color: $RoyalBlueDarker;
      }

      p {
        margin: 0;
        font-size: 12px;
      }
    }
  }

  @media (max-width: $phone) {
    .each-box {
      max-width: none;
      margin: 5px 10px;
    }
  }
}

section.option-box-container_order-edit {
  .each-box {
    max-width: 600px;

    p {
      &.date {
        font-style: italic;
        color: $grey;
      }
    }
  }
}

section.admin-customer-profile-button-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 95%;
  max-width: 1050px;
  margin-top: 30px;

  button {
    margin: 0 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: $smallPhone) {
    flex-direction: column;
    align-items: flex-end;
    button {
      margin: 5px 0;
    }
  }
}

section.admin-customer-profile,
section.admin-product-profile {
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 1050px;
  box-shadow: 1px 1px 10px 2px $greyShadow;
  margin: 10px;

  section.all-content {
    display: flex;
    flex-direction: column;
    margin: 20px 50px;
  }

  div.name-and-date {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }

  div.name {
    display: flex;
    flex-wrap: wrap;
    text-align: left;

    h3 {
      margin: 0;
      margin-right: 7px;
      font-size: 35px;
      font-weight: 600;

      &:first-child {
        color: $RoyalBlueDarker;
      }
    }
  }

  div.last-updated {
    text-align: left;
    p {
      margin: 0;
      color: $grey;
      font-style: italic;
    }
  }

  .line {
    margin: 0;
    margin-bottom: 20px;
    width: auto;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;

    span {
      width: 100%;
      margin-bottom: 10px;
      word-wrap: break-word;
      text-align: left;
    }
  }

  label.title-label {
    width: auto;
    margin: 0;
    height: auto;
    color: $RoyalBlue;
    font-weight: 700;
    border-bottom: 1px solid $greySuperLight;
    text-align: left;
  }

  @media (max-width: $phone) {
    section.all-content {
      margin: 10px;
    }
    div.name h3 {
      font-size: 25px;
    }
    div.last-updated p {
      font-size: 12px;
    }
    .content {
      padding-left: 5px;
    }
  }
}

section.admin-customer-eachOrder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;

  label.title-label {
    margin: 0;
    height: auto;
    color: $RoyalBlueDarker;
    font-weight: 700;
    border-bottom: 1px solid $greySuperLight;
    text-align: left;
  }

  div.eachOrder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 350px;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    margin: 10px;
    padding: 10px;

    div.line {
      margin: 10px 0;
      width: 100%;
    }

    p {
      margin: 0;
      text-align: left;
      &.orderId {
        font-size: 35px;
        font-weight: 700;
        margin-right: 10px;
      }
      &.city {
        font-size: 25px;
        font-weight: 600;
        color: $RoyalBlueDarker;
      }
      &.orderType {
        font-size: 18px;
        font-weight: 500;
      }
      &.updatedAt {
        font-style: italic;
        color: $grey;
        font-size: 12px;
      }
    }

    div.content-and-button {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      @media (max-width: 950px) {
        flex-direction: column;
      }

      div.id-and-city {
        text-align: left;
      }
    }

    div.orderDates {
      width: 100%;
      text-align: left;
    }

    div.buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      button.submitButton {
        min-width: 0;
        margin-right: 5px;
        &:last-child {
          margin: 0;
        }

        i {
          margin: 0;
        }
      }
    }

    @media (max-width: 950px) {
      max-width: 250px;
      flex-direction: column;
      p {
        &.city {
          font-size: 18px;
        }
        &.orderType {
          font-size: 14px;
        }
      }
      div.content {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    @media (max-width: 750px) {
      max-width: none;
    }
  }
}

.confirmation-modal {
  .title {
    border-bottom: 1px solid $greySuperLight;
    margin-bottom: 20px;

    h3 {
      text-align: center;
      margin: 0;
      font-size: 25px;
    }
  }

  .content {
    margin: 10px 0 30px 0;
    p {
      text-align: center;
    }
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    button {
      margin: 0 10px 10px 0;

      i { margin-right: 5px; }

      &:last-child {
        background: $grey;
      }
    }
  }
}

section.admin-product-profile {
  section.content-img {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    div.img {
      max-width: 250px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;

      img {
        width: 100%;
      }
    }

    div.content {
      border-left: 2px solid $RoyalBlueDarker;
      padding-left: 20px;
    }
    @media(max-width: $phone) {
      flex-direction: column;
      align-items: flex-start;
      div.img {
        max-width: 100px;
        margin: 10px auto;
      }
      div.content {
        padding-left: 10px;
        label.title-label {
          font-size: 14px;
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
}

section.admin-customer-order {
  display: flex;
  margin: 50px 10px;
  position: relative;

  div.button-bar {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin: 10px 0;

    button.submitButton {
      align-self: stretch;
      min-width: 0;
      height: 100%;
    }

    @media (max-width: 750px) {
      align-items: flex-start;

      button.submitButton {
        align-self: center;
        height: auto;
        font-size: 30px;
        background: transparent;
        color: #171717;
        position: sticky;
        top: 10em;
        padding: 0;

        &:disabled {
          color: $greySuperLight;
        }
      }
    }
  }
  section.admin-customer-eachOrder {
    max-width: 1200px;
    width: 100%;
  }
}

section.admin-customer-payment,
section.customer-payment {
  display: flex;
  flex-direction: column;
  max-width: 1050px;
  box-shadow: 1px 1px 10px 2px $greyShadow;
  margin: 10px;
  padding: 20px 10px;
  width: 95%;

  @media (max-width: $phone) {
    width: auto;
  }

  div.display-payment-block {
    display: flex;
    justify-content: center;
    width: 100%;

    button.submitButton {
      width: 100%;
      background: #373737;
      color: $white;

      &:hover {
        background: #171717;
        color: $RoyalBlue;
      }

      i {
        font-size: 21px;
      }
    }

    div.each-type {
      width: 100%;
      padding: 10px;

      &.recurring-payments {
        width: auto;
        min-width: 18em;

        @media (max-width: $midTablet) {
          min-width: 0;
        }
      }

      &:first-child {
        border-right: 2px solid $RoyalBlueDarker;
      }

      h2.blue-black-title {
        justify-content: flex-start;
        text-align: left;
        font-weight: 500;
        margin: 0;
        font-size: 24px;

        span {
          color: $kkUltraViolet;
        }
      }

      h2.blue-black-title-Montserrat {
        justify-content: flex-start;
        text-align: left;
        font-weight: 500;
        margin: 0;
        font-size: 24px;

        span {
          color: $kkUltraViolet;
        }
      }


      div.line {
        width: 100%;
        margin: 0;
      }

      div.monthly-rate {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        h4 {
          color: $RoyalBlue;
          font-size: 45px;
          margin: 0;
        }

        div {
          margin-left: 5px;
          margin-bottom: 15px;
          font-size: 20px;
          color: $grey;
          font-weight: 400;
          white-space: nowrap;
        }

        @media (max-width: $smallPhone) {
          h4 {
            font-size: 25px;
          }
          div {
            margin-bottom: 5px;
          }
        }
      }
    }

    @media (max-width: $midTablet) {
      flex-direction: column;


      div.each-type {
        width: auto !important;
        border: none !important;
        padding: 0;
      }
    }

    section.monthlyPayment {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 10px 0;
      box-shadow: 1px 1px 10px 2px $greyShadow;

      span.title {
        width: auto;
        padding: 2px 5px;
        background-image: linear-gradient(45deg, $RoyalBlueDarker 15%, $RoyalBlue 65%);
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        p {
          color: $white;
          margin: 0;
          font-size: 14px;
        }
      }

      div.all-cost-layout {
        display: flex;
        flex-direction: column;

        div.each {
          display: flex;
          justify-content: space-between;
          margin: 3px 0;

          span {
            font-size: 14px;
            font-weight: 400;

            &.cost {
              margin-right: 5px;
              margin-left: 15px;
              color: #171717;
              font-weight: 500;
            }
          }

          div.left-container {
            display: flex;
            div.orderId {
              padding: 1px;
              background-color: $RoyalBlueDarker;
              color: $white;
              min-width: 30px;
              font-size: 14px;
              font-weight: 400;
              cursor: pointer;

              &:hover {
                background-color: $RoyalBlue;
              }
            }
            span.updatedAt {
              color: $kkUltraViolet;
              font-size: 12px;
              margin-left: 10px;
              font-style: italic;
              text-align: left;
            }
          }
        }
      }

      div.total {
        display: flex;
        margin: 5px;
        justify-content: flex-end;

        span {
          &:first-child {margin-right: 5px; color: $RoyalBlueDarker; text-align: left;}
          &:last-child {margin-left: 5px; font-weight: 600;}
        }
      }
    }

    section.eachPayments {
      //border: 1px solid blue;
      display: flex;
      width: 100%;
      margin: 10px 0;
      box-shadow: 1px 1px 10px 2px $greyShadow;

      div.left-column {
        background-image: linear-gradient(45deg, $RoyalBlueDarker 15%, $RoyalBlue 65%);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        min-width: 2.5em;

        &:hover {
          background: $RoyalBlue;
        }

        p {
          margin: 0;
          color: $white;
          font-weight: 500;
          font-size: 25px;
        }

        @media (max-width: 300px) {
          min-width: 0;

          p {
            font-size: 15px;
          }
        }
      }

      main {
        width: 100%;

        div.lastUpdated {
          background: #fcfcfc;
          display: flex;
          align-items: flex-end;

          p {
            margin: 5px 0 5px 20px;
            font-size: 12px;
            font-style: italic;
            text-align: left;
          }

          @media (max-width: $smallPhone) {
            p {
              margin-left: 5px;
            }
          }
        }
      }

      div.eachPay {
        margin: 10px 0 10px 15px;
        text-align: left;
        border-left: 1px solid $greySuperLight;
        padding-left: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;

        @media (max-width: $smallPhone) {
          margin: 5px;
        }

        div.status {
          position: absolute;
          right: 0;
          top: 0;
          padding: 2px 5px;
          min-width: 3em;

          &.succeeded {
            background: #5ac295;
          }

          &.fail {
            background: $CherryRed;
          }

          p {
            margin: 0;
            color: $white;
            font-size: 12px;
            justify-self: stretch;
            text-align: center;
          }
        }

        div.total {
          display: flex;

          label.title-label, p {
            font-weight: 600;
          }

          p {
            margin: 0;
            margin-left: 5px;
            color: $RoyalBlue;
          }

          @media (max-width: $smallPhone) {
            margin-top: 25px;
          }
        }

        span.createdAt {
          font-weight: 400;
          color: $grey;
          font-style: italic;
          font-size: 12px;
          text-align: left;
        }
      }
    }
  }

  div {
    font-weight: 600;
  }
}

p.noRecurringPayments {
  margin-left: 0;
  text-align: left;
  font-style: italic;
  font-weight: 400;
}

section.customer-payment {
  max-width: 1300px;
  @media (max-width: $phone) {
    width: 90%;
  }
  div.display-recurring {
    div.each-type {
      &.recurring-payments {
        width: 100%;
        padding: 10px 20px;

        @media(max-width: $phone) {
          padding: 10px 0;
        }
      }
    }
    div.all-monthly-payments {
      p {
        text-align: left;
        margin: 10px 0;
        font-size: 14px;
      }
      section.monthlyPayment {
        div.all-cost-layout {
          div.each {
            border-radius: 7px;
            overflow: hidden;
            box-shadow: 1px 1px 5px 2px #f0f0f0;
            div.left-container {
              div.orderId {
                font-size: 22px;
                font-weight: 600;
                background-image: linear-gradient(45deg, $RoyalBlueDarker 15%, $RoyalBlue 65%);
                min-width: 2.5em;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                  background: $RoyalBlue;
                }
              }
              div.details {
                text-align: left;
                display: flex;
                flex-direction: column;
                padding: 0 10px;
                h6 {
                  margin: 0;
                  margin-top: 5px;
                  font-size: 18px;
                  color: $RoyalBlueDarker;
                  text-transform: uppercase;
                  font-weight: 600;
                }
                span.updatedAt {
                  margin-left: 0;
                }
                p {
                  margin: 10px 0;
                  font-size: 14px;
                  span {
                    color: $RoyalBlue;
                    text-decoration: underline;
                    cursor: pointer;
                    transition: 0.3s ease all;
                    &:hover {
                      color: $RoyalBlueDarker;
                    }
                  }
                }
              }
            }
            div.cost {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin-right: 5px;
              span.cost {
                font-size: 18px;
                font-weight: 600;
                justify-self: center;
              }
            }
          }
          button.submitButton {
            margin-top: 5px;
            border-radius: 7px;
          }
        }
        div.total {
          span {
            font-size: 30px;
          }
        }
      }
    }
    section.option-box-container {
      width: auto;
      min-width: 20em;
      align-items: flex-start;
      div.each-box {
        border-radius: 7px;
        @media(max-width: $phone) {
          margin: 0;
        }
      }
    }
  }

  div.display-transactions {
    div.each-type {
      &.all-transactions {
        border-right: none;
      }
      section.eachPayments {
        border-radius: 7px;
        overflow: hidden;
        main {
          div.header {
            background: $greyShadow;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            div.container {
              display: flex;
              div.block {
                text-align: left;
                h6, p, h4 {
                  margin: 0;
                }
                h6 {
                  font-size: 14px;
                  text-transform: uppercase;
                  font-weight: 600;
                }
                p {
                  font-size: 13px;
                }
                &.orderTypeBlk {
                  margin: 0 10px;
                  padding: 10px;
                }
                h4.orderType {
                  font-style: italic;
                  color: $RoyalBlueDarker;
                  text-transform: uppercase;
                }
              }
              button.submitButton {
                border-radius: 7px;
                min-width: 0;
                padding: 0 5px;
                overflow: hidden;
                width: 30px;
                justify-content: flex-end;
                transition: width 500ms ease, padding 500ms ease;
                &:hover {
                  width: 110px;
                  padding: 0 20px;
                }
              }
              @media (max-width: $phone) {
                flex-direction: column;
                div.block {
                  &.orderTypeBlk {
                    padding: 0;
                    margin: 0;
                    font-size: 12px;
                  }
                }
              }
            }

          }
        }
      }
      section.showMoreBtn {
        button.submitButton {
          border-radius: 7px;
        }
      }
    }
  }
  section.showMoreBtn {
    button.submitButton {
      margin: 0;
      max-width: none;
    }
  }
}

div.suitcase-selection-component {
  display: grid;
  grid-template-columns: 30% 70%;
  max-width: 1200px;
  width: 95%;
  border-radius: 0.5em;
  overflow: hidden;
  margin-top: 2em;

  @media (max-width: $tablet) {
    grid-template-columns: repeat(1, 1fr);
  }

  section.items-to-send-calculator {
    div.title-section {
      padding: 0.25em;
      background: $RoyalBlue;

      h3 {
        color: $white;
        font-weight: 300;
      }
    }

    div.calculator {
      padding: 1em;
      background: #f7f7f7;
    }

    span.error {
      font-size: 0.8em;
    }
  }
}

div.suitcase-calculator-input-container {
  display: grid;
  grid-template-columns: 3em calc(100% - 7em) 3em;
  grid-gap: 0.5em;
  place-items: center;
  padding-top: 1em;

  h5 {
    margin: 0;
    text-align: left;
  }

  input {
    width: 80%;
    height: 30px;
    padding-left: 5px;
    border: 1px solid $greySuperLight;
    box-shadow: 1px 1px 1px 1px $greyShadow inset;
    border-radius: 0 !important;
  }

  div.img-container {
    max-width: 3.5em;
    display: grid;
    place-items: center;

    img {
      width: 100%;
    }
  }
}

section.suitcase-table-container div.suitcase-table {
  margin: 0;
  border-radius: 0;
  height: 100%;
  border: 3px solid #f7f7f7;
  box-shadow: none;

  div.thead {
    padding: 20px;
    width: auto;
    background: #f7f7f7;
    div.tr {
      background: #f7f7f7;
      color: $black;
    }
  }

  div.tbody {
    padding: 20px;
    width: auto;
    height: 18em;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: #ffffff;
    }
    &::-webkit-scrollbar-thumb {
      background-image: linear-gradient(45deg, $RoyalBlue 0%, $RoyalBlueDarker, 75%, $RoyalBlueDarker 100%);
      border-radius: 1.5px;
    }

    div.tr {
      padding: 20px 0;
      border-top: 1px solid $greyShadow;
      border-bottom: 1px solid $greyShadow;

      &:first-of-type {
        border-top: 0;
      }

      &:nth-child(even) {
        background: $white;
      }

      span.link-span {
        color: $kkUltraVioletDark;
      }

      &:hover {
        background: $white;
        color: $black;
        border-top: 1px solid $greyShadow;
        border-bottom: 1px solid $greyShadow;

        &:first-of-type {
          border-top: 0;
        }
      }

      &:last-of-type, &:first-of-type {
        border-bottom: none !important;
      }
    }
  }
}

div.suitcase-warning-modal {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: $tablet) {
    grid-template-columns: repeat(1, 1fr);
    section {
      display: grid;
      place-items: center;

      h3, h5, p {
        text-align: center;
      }
    }
  }

  section {
    h3 {
      font-weight: 300;
      color: $RoyalBlue;
      font-size: 1.7em;
      margin-bottom: 0.2em;
    }

    h5 {
      margin-top: 0;
    }

    p {
      margin: 1em 0;
    }

    button.submitButton {
      div {
        text-transform: none;
        font-weight: 600;
      }
    }

    div.img-container {
      display: grid;
      place-items: center;
      padding: 3em;

      img {
        max-width: 15em;
        width: 100%;
      }
    }
  }
}

div.suitcase-mobile-view-table {

  div.mobile-row {
    padding: 1em 0;
  }

  label.title-label {
    margin: 10px 0;
  }

  ul {
    padding: 0;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h4 {
    margin: 0.5em 0;
    text-align: right;
  }
}

div.suitcase-price-and-next {
  padding: 2em 0;
  display: grid;
  place-items: center;

  div.payment-figure {
    margin-bottom: 1em;
  }
}


// SUGGESTED PACKAGES (PreselectedBoxesModal.tsx)
section.preselected-boxes-modal {
  display: grid;
  place-items: center;
  position: relative;
  padding-top: 3em;
  max-height: 85vh;

  button.submitButton {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 2.5em;
    i {
      margin: 0;
    }
  }

  section.selections {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;

    div.img-container {
      width: 100%;
      padding: 1em;

      img {
        max-width: 10em;
        width: 100%;
      }
    }

    @media(max-width: $tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media(max-width: $phone) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  div.predefined-box {
    position: relative;
    border: 3px solid $kkUltraVioletDark;
    padding-bottom: 3em;
    transition: all 0.5s ease-in-out;
    margin-top: 3em;

    @media(max-width: $tablet) {
      margin-top: 0;

      &:last-child {
        margin-bottom: 1em;
      }
    }

    &.popular {
      margin-top: 3em;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 3px 3px 10px 10px $kkUltraVioletVeryLight;   // was $greyShadow

      main {
        background: ghostwhite;
      }
    }

    div.most-popular-container {
      position: absolute;
      left: -2.75px;
      top: -3.4em;

      width: 100%;
      height: 3em;
      background: $kkUltraVioletLight;
      color: white;
      border: 3px solid $kkUltraVioletDark;
      display: grid;
      place-items: center;

      h3 {
        margin: 0;
      }
    }
  }

  main, .select-container {
    display: grid;
    place-items: center;
    text-align: center;
  }

  div.select-container {
    position: absolute;
    bottom: -3px;
    left: -3px;
    background: $kkLemonLime;
    border: 3px solid $kkUltraVioletDark;
    display: grid;
    place-items: center;
    color: black;
    width: 100%;
  }
}

span.terms-and-conditions {
  display: flex;
  justify-content: center;
  align-items: center;

  input[type='checkbox'] {
    width: 18px !important;
    height: 18px !important;
  }

  a {
    margin-left: 5px;
    color: $kkUltraViolet;

    &:hover {
      color: $kkUltraVioletDark;
    }
  }
}

.threeds-iframe-container {
  display: grid;
  place-items: center;
  width: 100%;
  max-width: 600px;
  height: 600px;

  & > iframe {
    width: 100%;
    height: 100%;
  }
}
